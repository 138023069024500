import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';

import { AlertService } from '../../services/alert.service';
import { CloseButtonComponent } from '../close-button/close-button.component';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    SvgIconComponent,
    NgTemplateOutlet,

    CloseButtonComponent,
  ],
})
export class AlertsComponent {
  constructor(public alertService: AlertService) {}
}
