<div class="articles-list-page">
  <div class="articles-list-page__header">
    <app-search-input
      class="search"
      [formControl]="searchInput"
      placeholder="Введите более 2 символов"
      i18n-placeholder
      (searchInputCleared)="searchCleared()">
    </app-search-input>
    <app-sort-switcher></app-sort-switcher>
  </div>
  <div class="articles-list-page__content">
    @for (
      article of articlesList();
      track article.uuid;
      let i = $index;
      let first = $first
    ) {
      <div class="article">
        @if (article | customTranslate: 'preview') {
          @if (
            isAlphabetSort &&
            (first ||
              (article | customTranslate: 'title')[0] !==
                (articlesList()[i - 1] | customTranslate: 'title')[0])
          ) {
            <span class="title-letter">
              {{ (article | customTranslate: 'title')[0].toUpperCase() }}
            </span>
          }

          <a class="article__title" [routerLink]="'./' + article.pathologyUuid">
            {{ article | customTranslate: 'title' }}
          </a>
          <div class="article__preview">
            {{ article | customTranslate: 'preview' }}
          </div>
        }
      </div>
    }
  </div>
</div>
