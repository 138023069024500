import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { ROUTING_MAP } from '../../models/constants/routing-map';
import { ServerErrorsService } from '../../services/server-errors/server-errors.service';

export const serverErrorsGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const router = inject(Router);
  const serverErrorsService = inject(ServerErrorsService);

  return (
    true ||
    serverErrorsService.isServerErrorPage() ||
    router.createUrlTree([ROUTING_MAP.index])
  );
};
