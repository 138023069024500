<app-collection-block>
  <ng-template #title>
    <ng-container i18n>Органы</ng-container>
  </ng-template>
  <ng-template #content>
    <div class="types-card-list">
      @for (organ of organsList(); track organ.uuid) {
        <app-collection-card
          [purchased]="organ.available || (isUserInWhiteList$ | async)"
          [inProgress]="!organ.firstBundleUuid"
          [content]="organ">
          <ng-template #mark>
            <app-collection-status
              markIconSrc="star-circle-selected-dark"
              unmarkIconSrc="star-circle-unselected-dark"
              [collectionItem]="{
                uuid: organ.uuid,
                type: collectionType.PathologyType,
              }"></app-collection-status>
          </ng-template>
          <ng-template #categoryTitle>
            {{ organ | customTranslate: 'categoryName' }}
          </ng-template>
          <ng-template #pathologyTitle>
            {{ organ | customTranslate: 'pathologyName' }}
          </ng-template>
          <ng-template #pathologyTypeTitle>
            {{ organ | customTranslate: 'type' }}
          </ng-template>
        </app-collection-card>
      }
    </div>
  </ng-template>
</app-collection-block>
