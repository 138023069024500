import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  CurrencyPipe,
  LowerCasePipe,
  NgClass,
  NgTemplateOutlet,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  Injector,
  OnInit,
  TemplateRef,
  ViewChild,
  effect,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChargebeeJsAngularWrapperModule } from '@chargebee/chargebee-js-angular-wrapper';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { Store } from '@ngrx/store';
import { Theme } from '@src/app/shared/models/enums/theme.enum';
import { getAllCategoriesForce } from '@store/categories/categories.actions';
import { getUserSubscriptions } from '@store/user/user.actions';
import { catchError, finalize, from, of, switchMap, take, tap } from 'rxjs';
import { PaymentApiService } from 'src/app/shared/api/payment-api.service';
import { BtnDirective } from 'src/app/shared/components/btn/btn.directive';
import { CloseButtonComponent } from 'src/app/shared/components/close-button/close-button.component';
import { CustomTranslatePipe } from 'src/app/shared/pipes/custom-translate/custom-translate.pipe';
import { ThemeService } from 'src/app/shared/services/theme/theme.service';

import { selectUserInfo } from '@store/user/user.selectors';
import { paymentFormLocalization } from './payment-form-localization';

interface CbFieldChangeEvent {
  cardType: any;
  complete: boolean;
  empty: boolean;
  error: {
    errorCode: string;
    message: string;
  };
  field: string;
  key: any;
  type: 'change';
}

@Component({
  selector: 'app-payment-card-form',
  templateUrl: './payment-card-form.component.html',
  styleUrls: ['./payment-card-form.component.scss'],
  standalone: true,
  imports: [
    BtnDirective,
    ChargebeeJsAngularWrapperModule,
    CloseButtonComponent,
    CurrencyPipe,
    CustomTranslatePipe,
    FormsModule,
    LowerCasePipe,
    NgClass,
    NgTemplateOutlet,
    ReactiveFormsModule,
    SvgIconComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCardFormComponent implements OnInit {
  @ViewChild('postValidationDialog', { read: TemplateRef })
  public pvdRef: TemplateRef<any>;

  public cardValidationInProgress: boolean;

  public paymentInProgress: boolean;

  public paymentResult: 'error' | 'success' = null;

  public paymentResultMessage: {
    title: string;
    message?: string;
  };

  public paymentFormLocalization = paymentFormLocalization;

  public token: string;

  public cardValidationError: string = '';

  public pvdData = {
    type: 'success',
  };

  public validationErrors: {
    [key: string]: {
      errorCode: string;
      message: string;
    };
  };

  public cardComponent: any;

  public styles = {
    base: {
      color: '#363636',
      fontWeight: '500',
      fontFamily: 'Manrope, sans-serif',
      fontSize: '18px',
      fontSmoothing: 'antialiased',
      iconColor: '#73767e',

      '::placeholder': {
        color: 'transparent',
      },

      ':focus::placeholder': {
        color: '#73767E',
      },
    },

    invalid: {
      iconColor: '#73767e',

      '::placeholder': {
        color: 'transparent',
      },
    },
  };

  public placeholder = {
    number: ' ',
    expiry: 'MM / YY',
    cvv: ' ',
  };

  public classes = {
    focus: 'focus',
    invalid: 'invalid',
    empty: 'empty',
    complete: 'complete',
  };

  constructor(
    @Inject(DIALOG_DATA) public dialogData: any,
    private cdRef: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    private dialogRef: DialogRef,
    private injector: Injector,
    private paymentApiService: PaymentApiService,
    private store: Store,
    private themeService: ThemeService
  ) {}

  public ngOnInit(): void {
    this.setColors();

    this.dialogRef.backdropClick
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.paymentResult) {
          this.dialogRef.close();
        }
      });
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public setValidationErrors(e: CbFieldChangeEvent): void {
    if (!this.validationErrors) {
      this.validationErrors = {
        [e.field]: e.error,
      };
    }
    this.validationErrors[e.field] = e.error;
    this.cdRef.detectChanges();
  }

  public purchase() {
    this.cardValidationInProgress = true;

    this.store
      .select(selectUserInfo)
      .pipe(
        take(1),
        switchMap(user =>
          from(
            this.cardComponent.tokenize({
              addressLine1: user.email,
              addressLine2: this.dialogData.product.productId,
            }) as Promise<{ token: string }>
          )
        ),
        tap(data => {
          this.cardValidationInProgress = false;
          this.token = data.token;
          this.cardValidationError = '';
        }),
        catchError(err => {
          this.cardValidationInProgress = false;

          switch (err.code) {
            case 'ChargebeeClientError':
              this.cardValidationError = 'ChargebeeClientError';

              break;
          }

          this.token = '';

          return of(null);
        }),
        switchMap(data => {
          this.paymentInProgress = true;

          if (!data) return of(null);

          return this.paymentApiService.purchase({
            productId: this.dialogData.product.productId,
            tokenId: data.token,
          });
        }),
        finalize(() => {
          this.cdRef.detectChanges();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: res => {
          if (res) {
            this.paymentResult = 'success';

            this.paymentResultMessage = {
              title: this.paymentFormLocalization.success.title,
              message: this.paymentFormLocalization.success.text,
            };
            this.store.dispatch(getUserSubscriptions());
          }
          this.paymentInProgress = false;

          this.store.dispatch(getAllCategoriesForce());

          this.cdRef.detectChanges();
        },
        error: err => {
          this.paymentInProgress = false;
          this.paymentResult = 'error';

          switch (err.error.code) {
            case '3001':
              this.paymentResultMessage = {
                title: this.paymentFormLocalization.errors[3001].title,
              };

              break;

            default:
              this.paymentResultMessage = {
                title: this.paymentFormLocalization.errors['common'].title,
                message: this.paymentFormLocalization.errors['common'].text,
              };

              break;
          }

          this.cdRef.detectChanges();
        },
      });
  }

  public setComponent(cardComponent: any): void {
    this.cardComponent = cardComponent;
  }

  private setColors(): void {
    effect(
      () => {
        if (this.themeService.rootTheme() === Theme.Dark) {
          this.styles.base.color = '#ffffffe6';
          this.styles.base.iconColor = '#73767e';
          this.styles.invalid.iconColor = '#f2b8b5';
        } else {
          this.styles.base.color = '#363636';
          this.styles.base.iconColor = '#ffffff80';
          this.styles.invalid.iconColor = '#eb5757';
        }
      },
      { injector: this.injector }
    );
  }
}
