import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Theme } from '../../../shared/models/enums/theme.enum';
import { ThemeService } from '../../../shared/services/theme/theme.service';

@Component({
  selector: 'app-theming',
  standalone: true,
  templateUrl: './theming.component.html',
  styleUrls: ['./theming.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemingComponent {
  public readonly THEMES = [
    {
      imageUrl: 'assets/img/auto-theme.png',
      text: $localize`Авто`,
      theme: Theme.Auto,
    },
    {
      imageUrl: 'assets/img/dark-theme.png',
      text: $localize`Темная`,
      theme: Theme.Dark,
    },
    {
      imageUrl: 'assets/img/light-theme.png',
      text: $localize`Светлая`,
      theme: Theme.Light,
    },
  ];

  constructor(public themeService: ThemeService) {}

  public setTheme(theme: Theme): void {
    this.themeService.setTheme(theme);
  }
}
