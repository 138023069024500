<svg-icon class="search-icon" key="search"></svg-icon>

<input
  #input
  class="input"
  type="text"
  [formControl]="formControl"
  [placeholder]="placeholder" />

@if (input.value) {
  <app-close-button class="clear" (click)="clearInput()" />
}
