<div class="all-collection">
  @if (blocksVisibility['bundles']) {
    <div class="collection-item">
      <app-models></app-models>
    </div>
  }
  @if (blocksVisibility['types']) {
    <div class="collection-item">
      <app-types></app-types>
    </div>
  }
  @if (blocksVisibility['pathologies']) {
    <div class="collection-item">
      <app-pathology></app-pathology>
    </div>
  }
  @if (blocksVisibility['organs']) {
    <div class="collection-item">
      <app-organs></app-organs>
    </div>
  }
  @if (blocksVisibility['organSystems']) {
    <div class="collection-item">
      <app-organ-system></app-organ-system>
    </div>
  }
  @if (blocksVisibility['articles']) {
    <div class="collection-item">
      <app-articles></app-articles>
    </div>
  }
</div>
