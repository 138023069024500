import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SvgIconComponent } from '@ngneat/svg-icon';

import { CloseButtonComponent } from '../../../components/close-button/close-button.component';
import { BaseControlDirective } from '../../base-control.directive';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CloseButtonComponent, ReactiveFormsModule, SvgIconComponent],
})
export class SearchInputComponent extends BaseControlDirective {
  @Output() public searchInputCleared = new EventEmitter<boolean>();

  public clearInput(): void {
    this.searchInputCleared.emit(true);
  }
}
