import { Routes } from '@angular/router';

// import { SubscriptionComponent } from './subscriptions/subscription/subscription.component';
// import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { ROUTING_MAP } from '../../shared/models/constants/routing-map';
// import { CATEGORY_UUID_KEY } from 'src/app/shared/constants/routing-params-keys';
import { pageMetaGuard } from '@app/shared/guards/page-meta.guard';
import { routesMetadata } from '@app/shared/models/constants/routes-metadata';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { ProSubscriptionComponent } from './pro-subscription/pro-subscription.component';
import { ThemingComponent } from './theming/theming.component';

export const PROFILE_ROUTES: Routes = [
  {
    path: ROUTING_MAP.profile.personal,
    component: PersonalInformationComponent,
    canActivate: [pageMetaGuard],
    data: { ...routesMetadata[ROUTING_MAP.profile.index] },
  },
  // {
  //   path: `${ROUTING_MAP.profile.subscription}/:${CATEGORY_UUID_KEY}`,
  //   component: SubscriptionComponent,
  // },
  {
    path: ROUTING_MAP.profile.subscription,
    component: ProSubscriptionComponent,
    canActivate: [pageMetaGuard],
    data: { ...routesMetadata[ROUTING_MAP.profile.index] },
  },
  {
    path: ROUTING_MAP.profile.changePassword,
    component: ChangePasswordComponent,
    canActivate: [pageMetaGuard],
    data: { ...routesMetadata[ROUTING_MAP.profile.changePassword] },
  },
  {
    path: ROUTING_MAP.profile.theming,
    component: ThemingComponent,
    canActivate: [pageMetaGuard],
    data: { ...routesMetadata[ROUTING_MAP.profile.index] },
  },
  {
    path: '',
    redirectTo: ROUTING_MAP.profile.personal,
    pathMatch: 'full',
  },
];
