import { AsyncPipe, isPlatformBrowser, Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticleViewerComponent } from '@app/shared/components/article-viewer/article-viewer.component';
import { BtnDirective } from '@app/shared/components/btn/btn.directive';
import { CollectionStatusComponent } from '@app/shared/components/collection-status/collection-status.component';
import {
  LinkShareComponent,
  LinkType,
} from '@app/shared/components/link-share/link-share.component';
import { ROUTING_MAP } from '@app/shared/models/constants/routing-map';
import { CATEGORY_UUID_ROUTE_PARAM } from '@app/shared/models/constants/routing-params-keys';
import { CollectionType } from '@app/shared/models/enums/collection-type.enum';
import { LocalizedKey } from '@app/shared/models/types/localized-key';
import { CustomTranslatePipe } from '@app/shared/pipes/custom-translate/custom-translate.pipe';
import { MetaService } from '@app/shared/services/meta/meta.service';
import { PrintService } from '@app/shared/services/print/print.service';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { Store } from '@ngrx/store';
import { IArticleItem } from '@src/app/shared/models/interfaces/article/article-item.interface';
import {
  articleComponentDestroyed,
  articleComponentInitialized,
  getArticleBlob,
} from '@store/articles/articles.actions';
import {
  selectArticle,
  selectArticleBlob,
  selectIsArticleBlobDownloading,
} from '@store/articles/articles.selectors';
import FileSaver from 'file-saver';
import { capitalize } from 'lodash-es';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ArticleViewerComponent,
    AsyncPipe,
    BtnDirective,
    CollectionStatusComponent,
    CustomTranslatePipe,
    LinkShareComponent,
    SvgIconComponent,
  ],
})
export class ArticleComponent implements OnInit, OnDestroy {
  @ViewChild('articleTitle')
  public articleTitleRef: ElementRef<HTMLSpanElement>;

  @ViewChild('articleContent')
  public articleContentRef: ElementRef<HTMLDivElement>;

  public readonly collectionType = CollectionType;

  public article: IArticleItem;

  public linkType = LinkType;

  public downloadInProgress = toSignal(
    this.store.select(selectIsArticleBlobDownloading)
  );

  private customTranslate = new CustomTranslatePipe();

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject(PLATFORM_ID) private platformId: any,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    private location: Location,
    private metaService: MetaService,
    private printService: PrintService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.initArticle();
  }

  public back(): void {
    if (history.state.navigationId > 1) {
      this.location.back();
    } else {
      this.router.navigate(['../'], {
        relativeTo: this.route,
      });
    }
  }

  public goToPathology(): void {
    const categoryUuid = this.route.snapshot.params[CATEGORY_UUID_ROUTE_PARAM];

    // TODO: заменить ROUTING_MAP.pathology на динамическое определение исходя из отношения статьи к категории с патологиями или анатомией (нужен параметр с бэка где-то рядом со статьей)
    this.router.navigate([
      ROUTING_MAP.pathology,
      categoryUuid,
      this.article.pathologyUuid,
    ]);
  }

  public print(): void {
    const htmlRaw =
      `<span class="title">${this.articleTitleRef.nativeElement.innerHTML}</span>` +
      this.articleContentRef.nativeElement.innerHTML;

    this.printService.print(htmlRaw);
  }

  public downloadArticle(): void {
    this.store
      .select(selectArticleBlob)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(articlePDF => {
        if (articlePDF) {
          FileSaver.saveAs(
            articlePDF,
            this.customTranslate.transform(this.article, 'title') + '.pdf'
          );
        }
      });

    this.store.dispatch(
      getArticleBlob({ pathologyUuid: this.article.pathologyUuid })
    );
  }

  private initArticle(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }

    this.store
      .select(selectArticle)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(article => {
        if (article) {
          this.article = article;

          const title =
            this.article[
              ('title' + capitalize(this.locale)) as LocalizedKey<
                IArticleItem,
                'title'
              >
            ];

          this.metaService.setMetadata({
            meta: [
              {
                property: 'og:title',
                content: title,
              },
            ],
            title,
          });

          this.cdr.markForCheck();
        }
      });

    this.route.params
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(params => {
        this.store.dispatch(
          articleComponentInitialized({
            pathologyUuid: params['uuid'],
            categoryUuid: params['categoryUuid'],
          })
        );
      });
  }

  public ngOnDestroy(): void {
    this.store.dispatch(articleComponentDestroyed());
  }
}
