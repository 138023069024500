import { NgStyle, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnInit,
  PLATFORM_ID,
  afterNextRender,
  effect,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AlertsComponent } from '@app/shared/components/alerts/alerts.component';
import { AppLoaderComponent } from '@app/shared/components/app-loader/app-loader.component';
import { LocalStorageKeys } from '@app/shared/models/enums/local-storage-keys.enum';
import { LocalStorageService } from '@app/shared/services/local-storage/local-storage.service';
import { MobileDeviceService } from '@app/shared/services/mobile-device.service';
import { enableAnalytics } from '@app/shared/utility/enable-analytics.utility';
import { patchState, signalState } from '@ngrx/signals';
import { timer } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AlertsComponent, AppLoaderComponent, NgStyle, RouterOutlet],
})
export class AppComponent implements OnInit {
  @HostBinding('class.app-loaded') public appLoaded = false;

  public appState = signalState({
    appLoading: true,
    loaderBaseShowTimePassed: false,
  });

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private localStorageService: LocalStorageService,
    private mobileDeviceService: MobileDeviceService
  ) {
    this.watchAppLoading();

    afterNextRender(() => {
      patchState(this.appState, state => ({ ...state, appLoading: false }));
    });
  }

  public ngOnInit(): void {
    this.mobileDeviceService.detectDevicePlatform();
    this.enableAnalytics();
    this.setLoaderBaseShowTime();
  }

  private enableAnalytics(): void {
    if (
      isPlatformBrowser(this.platformId) &&
      this.localStorageService.getItem(LocalStorageKeys.AllowCookie)
        ?.acceptPerformanceCookies
    ) {
      enableAnalytics();
    }
  }

  private watchAppLoading() {
    effect(() => {
      this.appLoaded =
        !this.appState.appLoading() && this.appState.loaderBaseShowTimePassed();
    });
  }

  private setLoaderBaseShowTime(): void {
    if (isPlatformBrowser(this.platformId)) {
      timer(650).subscribe(() => {
        patchState(this.appState, state => ({
          ...state,
          loaderBaseShowTimePassed: true,
        }));
      });
    }
  }
}
