import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ArticleApiService } from '@app/shared/api/article/article-api.service';
import { IArticleListItem } from '@app/shared/models/interfaces/article/article-list-item.interface';
import { LocalizedKey } from '@app/shared/models/types/localized-key';
import { Sort } from '@app/shared/models/types/sort.type';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { selectInitialCategoryArticles } from '@store/articles/articles.selectors';
import { capitalize, sortBy } from 'lodash-es';
import { catchError, map, mergeMap, of, take } from 'rxjs';
import * as ArticlesActions from './articles.actions';

@Injectable()
export class ArticlesEffects {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private actions$: Actions,
    private articleApiService: ArticleApiService,
    private store: Store
  ) {}

  public getArticleBlob$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ArticlesActions.getArticleBlob),
      mergeMap(action => {
        return this.articleApiService.getArticle$(action.pathologyUuid).pipe(
          map(articleBlob =>
            ArticlesActions.getArticleBlobSuccess({ articleBlob })
          ),
          catchError(error =>
            of(
              ArticlesActions.getArticleBlobFailure({
                error: error?.message,
              })
            )
          )
        );
      })
    );
  });

  public getArticle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ArticlesActions.getArticle,
        ArticlesActions.articleComponentInitialized
      ),
      mergeMap(action => {
        return this.articleApiService
          .getArticleHtmlByPathologyUuid$(action.pathologyUuid)
          .pipe(
            map(article => ArticlesActions.getArticleSuccess({ article })),
            catchError(error =>
              of(
                ArticlesActions.getArticleFailure({
                  error: error?.message,
                })
              )
            )
          );
      })
    );
  });

  public unsetArticleBlob$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ArticlesActions.articleComponentDestroyed),
      map(() => {
        return ArticlesActions.unsetArticleBlob();
      })
    );
  });

  public unsetArticle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ArticlesActions.articleComponentDestroyed),
      map(() => {
        return ArticlesActions.unsetArticle();
      })
    );
  });

  public setActiveArticlesCategoryUuid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ArticlesActions.articleComponentInitialized,
        ArticlesActions.articlesListComponentInitialized
      ),
      map(action => {
        return ArticlesActions.setActiveArticlesCategoryUuid({
          categoryUuid: action.categoryUuid,
        });
      })
    );
  });

  public getArticlesList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ArticlesActions.articlesListComponentInitialized),
      mergeMap(action => {
        return this.articleApiService.getArticleList$(action.categoryUuid).pipe(
          map(articles => ArticlesActions.getArticlesSuccess({ articles })),
          catchError(error =>
            of(
              ArticlesActions.getArticlesFailure({
                error: error?.message,
              })
            )
          )
        );
      })
    );
  });

  public sortAndSearchArticles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ArticlesActions.sortAndSearchArticles),
      mergeMap(action => {
        return this.store.select(selectInitialCategoryArticles).pipe(
          take(1),
          map(articles => {
            return ArticlesActions.articlesSortAndSearchResult({
              articles: this.sortAndSearchArticles(
                action.sortType,
                action.searchValue,
                articles
              ),
            });
          })
        );
      })
    );
  });

  private sortAndSearchArticles(
    sortType: Sort,
    searchValue: string,
    articles: IArticleListItem[] = []
  ): IArticleListItem[] {
    const title = ('title' + capitalize(this.locale)) as LocalizedKey<
      IArticleListItem,
      'title'
    >;

    const searchedArticles =
      searchValue.length > 2 && articles
        ? articles.filter(article => {
            return article[title].toLowerCase().includes(searchValue);
          })
        : articles;

    switch (sortType) {
      case Sort.AtoZ: {
        return sortBy(searchedArticles, [title]);
      }
      case Sort.ZtoA: {
        return sortBy(searchedArticles, [title]).reverse();
      }
      default: {
        return sortBy(searchedArticles, ['sequence']);
      }
    }
  }
}
