import {
  FullscreenOverlayContainer,
  OverlayContainer,
} from '@angular/cdk/overlay';
import {
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { routes } from '@app/app.routes';
import { httpInterceptorProviders } from '@app/shared/interceptors/interceptors';
import { SVG_REGISTRY } from '@app/shared/models/constants/svg-registry';
import { ThemeService } from '@app/shared/services/theme/theme.service';
import { TokenService } from '@app/shared/services/token/token.service';
import { environment } from '@env/environment';
import { provideSvgIcons, provideSvgIconsConfig } from '@ngneat/svg-icon';
import { Actions, ofType, provideEffects } from '@ngrx/effects';
import { Store, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { effects } from '@store/effects';
import { reducers } from '@store/reducers';
import {
  getUser,
  getUserFailure,
  getUserSuccess,
} from '@store/user/user.actions';
import { QuillModule } from 'ngx-quill';
import { Observable, take } from 'rxjs';

function initializeTheme(themeService: ThemeService) {
  return (): Observable<void> => themeService.setAppDefaultTheme();
}

function initializeUser(
  store: Store,
  actions: Actions,
  tokenService: TokenService
): () => Promise<void> {
  return () =>
    new Promise(resolve => {
      if (tokenService.getAccessToken()) {
        actions
          .pipe(ofType(getUserSuccess, getUserFailure), take(1))
          .subscribe(() => {
            resolve();
          });
        store.dispatch(getUser());
      } else {
        resolve();
      }
    });
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideStore(reducers),
    provideRouter(
      routes,
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'top',
      })
    ),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: environment.production,
      autoPause: true,
    }),
    provideEffects(effects),
    importProvidersFrom(QuillModule.forRoot()),
    httpInterceptorProviders,
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTheme,
      deps: [ThemeService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeUser,
      deps: [Store, Actions, TokenService],
      multi: true,
    },
    // provideClientHydration(),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideSvgIcons(SVG_REGISTRY),
    provideSvgIconsConfig({
      sizes: {
        xs: '10px',
        sm: '12px',
        md: '16px',
        lg: '20px',
        'lg-plus': '22.5px',
        xl: '24px',
        xxl: '32px',
      },
      defaultSize: 'lg-plus',
    }),
  ],
};
