import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { UUID_REGEXP } from '../../models/constants/regexp';
import { ROUTING_MAP } from '../../models/constants/routing-map';

export const checkUuidGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const router = inject(Router);
  const urlSegment = route.url.toString().split(',');

  if (
    urlSegment.every(url => UUID_REGEXP.test(url)) ||
    urlSegment[0] === 'all' ||
    urlSegment[0] === ROUTING_MAP.pathology ||
    urlSegment[0] === ROUTING_MAP.anatomy
  ) {
    return true;
  }

  return router.createUrlTree([ROUTING_MAP.notFound]);
};
