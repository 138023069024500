import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Category } from '@app/shared/models/interfaces/category';
import { LocalizedKey } from '@app/shared/models/types/localized-key';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { selectAllCategories } from '@store/categories/categories.selectors';
import { capitalize } from 'lodash-es';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { CategoryAPIService } from 'src/app/shared/api/category/category-api.service';
import * as CategoriesActions from './categories.actions';

@Injectable()
export class CategoriesEffects {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private actions$: Actions,
    private store: Store,
    private categoriesApiService$: CategoryAPIService
  ) {}

  public getAllCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        CategoriesActions.getAllCategories,
        CategoriesActions.getAllCategoriesForce
      ),
      concatLatestFrom(() => this.store.select(selectAllCategories)),
      filter(([action, existingCategories]) =>
        Boolean(
          !existingCategories.length ||
            action.type === '[App] Get All Categories Force'
        )
      ),
      switchMap(() => {
        return this.categoriesApiService$.getCategories$().pipe(
          map(categories => {
            const categoryTitle =
              `hasArticles${capitalize(this.locale)}` as LocalizedKey<
                Category,
                'hasArticles'
              >;

            const categoriesWithArticles = categories.filter(
              category => category[categoryTitle]
            );

            return CategoriesActions.getAllCategoriesSuccess({
              categories,
              categoriesWithArticles,
            });
          }),
          catchError(error =>
            of(
              CategoriesActions.getAllCategoriesFailure({
                error: error?.message,
              })
            )
          )
        );
      })
    );
  });
}
