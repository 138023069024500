<app-collection-block>
  <ng-template #title>
    <span class="pathology-title" i18n> Патологии </span>
  </ng-template>
  <ng-template #content>
    @for (pathology of pathologiesList(); track pathology.uuid) {
      <div class="pathology">
        <span class="category-title">
          {{ pathology | customTranslate: 'categoryName' }}
        </span>
        <app-pathology-card-list
          [categoryUuid]="pathology.categoryUuid"
          [countOfItems]="pathology.bundlesCountInPathology"
          [collectionItem]="{
            uuid: pathology.uuid,
            type: collectionType.Pathology,
          }"
          [isPathology]="pathology.parentIsPathology">
          <span
            title
            [routerLink]="
              '/' +
              (pathology['pathology']
                ? routingMap.pathology
                : routingMap.anatomy) +
              '/' +
              pathology.categoryUuid +
              '/' +
              pathology.uuid
            ">
            {{ pathology | customTranslate: 'pathology' }}
          </span>
        </app-pathology-card-list>
      </div>
    }
  </ng-template>
</app-collection-block>
