import { Dialog } from '@angular/cdk/dialog';
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Inject,
  Input,
  LOCALE_ID,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { MobileDeviceService } from '@app/shared/services/mobile-device.service';
import {
  CollectionBundle,
  CollectionPathologyType,
} from '@app/signal-store/collection/collection-state.interface';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { ImageNotFoundDirective } from '../../directives/image-not-found/image-not-found.directive';
import { IN_PROGRESS_DIALOG_CONFIG } from '../../models/constants/dialog-configs';
import { ROUTING_MAP } from '../../models/constants/routing-map';
import { ICollectionCard } from '../../models/interfaces/collection-card.interface';
import { NumeralsAdaptiveTextPipe } from '../../pipes/numerals-adaptive-text/numerals-adaptive-text.pipe';
import { InProgressPopupComponent } from '../in-progress-popup/in-progress-popup.component';
import { PurchaseUnavailableItemComponent } from '../purchase-unavailable-item/purchase-unavailable-item.component';

@Component({
  selector: 'app-collection-card',
  templateUrl: './collection-card.component.html',
  styleUrls: ['./collection-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SvgIconComponent,
    NgTemplateOutlet,
    ImageNotFoundDirective,
    NumeralsAdaptiveTextPipe,
  ],
})
export class CollectionCardComponent {
  @ContentChild('categoryTitle') public categoryTitleRef: TemplateRef<any>;

  @ContentChild('pathologyTitle') public pathologyTitleRef: TemplateRef<any>;

  @ContentChild('pathologyTypeTitle')
  public pathologyTypeTitleRef: TemplateRef<any>;

  @ContentChild('modelTitle') public modelTitleRef: TemplateRef<any>;

  @ContentChild('mark') public markRef!: TemplateRef<any>;

  public cardContent: ICollectionCard;

  @Input() public purchased: boolean = false;

  @Input() public inProgress: boolean = false;

  @Input() public set content(
    value: CollectionBundle | CollectionPathologyType
  ) {
    if ((value as CollectionBundle)['modelNameDe']) {
      value = value as CollectionBundle;
      this.cardContent = {
        imgUrl: value.thumbnail,
        categoryUuid: value.categoryUuid,
        pathologyUuid: value.pathologyUuid,
        pathologyTypeUuid: value.pathologyTypeUuid,
        modelUuid: value.uuid,
      };
    }

    if ((value as CollectionPathologyType).bundlesCountInPathologyType) {
      value = value as CollectionPathologyType;
      this.cardContent = {
        imgUrl: value.firstBundleUrl,
        modelsNum: value.bundlesCountInPathologyType,
        categoryUuid: value.categoryUuid,
        pathologyUuid: value.pathologyUuid,
        pathologyTypeUuid: value.uuid,
        modelUuid: value.firstBundleUuid,
      };
    }
  }

  constructor(
    @Inject(LOCALE_ID) public currentLanguage: string,
    public mobileDeviceService: MobileDeviceService,
    private dialog: Dialog,
    private router: Router
  ) {}

  public openModelViewPage(): void {
    if (
      Boolean(this.cardContent.modelUuid && !this.inProgress && this.purchased)
    ) {
      this.router.navigate(
        [
          ROUTING_MAP.models,
          this.cardContent.categoryUuid,
          this.cardContent.pathologyUuid,
          this.cardContent.pathologyTypeUuid,
          this.cardContent.modelUuid,
        ],
        {}
      );
    } else if (this.inProgress) {
      this.dialog.open(InProgressPopupComponent, IN_PROGRESS_DIALOG_CONFIG);
    } else if (!this.inProgress) {
      this.dialog.open(PurchaseUnavailableItemComponent);
    }
  }
}
