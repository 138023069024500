import { Dialog } from '@angular/cdk/dialog';
import {
  AsyncPipe,
  CurrencyPipe,
  DatePipe,
  LowerCasePipe,
  NgTemplateOutlet,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostBinding,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { SvgIconComponent, SvgIcons } from '@ngneat/svg-icon';
import { Store } from '@ngrx/store';
import { AlertStatus } from '@src/app/shared/models/enums/alert-status.enum';
import {
  Category,
  PaymentPlatform,
  Product,
} from '@src/app/shared/models/interfaces/category';
import { Subscription } from '@src/app/shared/models/interfaces/subscription.interface';
import { getUserSubscriptions } from '@store/user/user.actions';
import { selectUserSubscriptions } from '@store/user/user.selectors';
import { EMPTY, catchError, finalize, iif, switchMap, throwError } from 'rxjs';
import { PaymentApiService } from 'src/app/shared/api/payment-api.service';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure/are-you-sure.component';
import { BtnDirective } from 'src/app/shared/components/btn/btn.directive';
import { AlertService } from 'src/app/shared/services/alert.service';

import { PaymentCardFormComponent } from '../components/payment-card-form/payment-card-form.component';

interface Feature {
  icon: SvgIcons;
  title: string;
  text: string;
}

@Component({
  selector: 'app-subscriptions',
  standalone: true,
  imports: [
    AsyncPipe,
    BtnDirective,
    CurrencyPipe,
    DatePipe,
    LowerCasePipe,
    NgTemplateOutlet,
    RouterModule,
    SvgIconComponent,
  ],
  templateUrl: './pro-subscription.component.html',
  styleUrls: ['./pro-subscription.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProSubscriptionComponent implements OnInit {
  @HostBinding('class.bought') public get boughtView(): boolean {
    return !!this.subscription;
  }

  public purchasedCategories: Category[] = [];

  public notPurchasedCategories: Category[] = [];

  public subscription: Product;

  public unsubscribing = false;

  public features: Feature[] = [
    {
      icon: 'lock',
      title: $localize`Все модели каталога`,
      text: $localize`После оформления подписки вам будут доступны все 3D модели каталога без ограничений.`,
    },
    {
      icon: 'burger-half',
      title: $localize`Регулярные обновления`,
      text: $localize`Вам будут доступны все функциональные возможности в регулярных обновлениях приложения, а также новые 3D модели.`,
    },
    {
      icon: 'devices',
      title: $localize`Доступ с любого устройства`,
      text: $localize`Используйте один email для доступа к каталогу с любого типа устройств.`,
    },
  ];

  public activePlan = 0;

  public paymentPlatformLocalization: Record<PaymentPlatform, string> = {
    ANDROID: $localize`Оформлено с Google Play`,
    IOS: $localize`Оформлено с App Store`,
    TRIAL: '',
    WEB: $localize`Оформлено с catalog.voka.io`,
  };

  public subscriptionPlans: Subscription;

  public periodLocalization: Record<string, string> = {
    month: $localize`ежемесячно`,
    year: $localize`ежегодно`,
  };

  constructor(
    private alertService: AlertService,
    private cdRef: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    private dialog: Dialog,
    private paymentApiService: PaymentApiService,
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.store
      .select(selectUserSubscriptions)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(subs => {
        if (subs.length) {
          this.subscriptionPlans = subs[0];
          this.subscription = this.subscriptionPlans.product.find(
            p => p.purchasedInfo
          );
        }

        this.cdRef.markForCheck();
      });
  }

  public openPaymentDialog(): void {
    this.dialog.open(PaymentCardFormComponent, {
      disableClose: true,
      data: {
        category: {
          categoryEn: $localize`PRO Подписка`,
          categoryDe: $localize`PRO Подписка`,
          categoryRu: $localize`PRO Подписка`,
        },
        product: this.subscriptionPlans.product[this.activePlan],
      },
    });
  }

  public unsubscribe(): void {
    this.unsubscribing = true;

    const comfirmDialofRef = this.dialog.open(AreYouSureComponent, {
      data: {
        title: $localize`Отмена подписки`,
        message: $localize`Вы уверены, что хотите отменить подписку?`,
        confirmText: $localize`Да, отменить`,
        cancelText: $localize`Нет, не отменять`,
      },
    });

    comfirmDialofRef.closed
      .pipe(
        switchMap(confirmed =>
          iif(
            () => !!confirmed,
            this.paymentApiService.cancelSubscription({
              productId: this.subscription.productId,
            }),
            EMPTY
          )
        ),
        catchError(err => {
          switch (err.status) {
            case 300:
              window.open(err.error, '_blank');

              break;

            default:
              this.alertService.show({
                message: err.error.message,
                title: err.error.title,
                status: AlertStatus.Error,
              });
              break;
          }

          return throwError(() => err);
        }),
        finalize(() => {
          this.unsubscribing = false;
          this.cdRef.markForCheck();
        })
      )
      .subscribe(data => {
        this.alertService.show({
          message: data.message,
          title: data.title,
          status: AlertStatus.Success,
        });
        this.store.dispatch(getUserSubscriptions());
      });
  }
}
