import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
} from '@angular/router';
import { SortSwitcherComponent } from '@app/shared/components/sort-switcher/sort-switcher.component';
import { SearchInputComponent } from '@app/shared/controls/input/search-input/search-input.component';
import { ARTICLE_VIEW_URL_REGEXP } from '@app/shared/models/constants/regexp';
import { CustomTranslatePipe } from '@app/shared/pipes/custom-translate/custom-translate.pipe';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Sort } from '@src/app/shared/models/types/sort.type';
import {
  articlesListComponentInitialized,
  getArticlesFailure,
  getArticlesSuccess,
  setArticlesSearchState,
  sortAndSearchArticles,
  unsetArticles,
} from '@store/articles/articles.actions';
import {
  selectArticlesSearchState,
  selectUserMappedArticles,
} from '@store/articles/articles.selectors';
import { selectSortKey } from '@store/sort/sort.selectors';
import { combineLatest, filter, take } from 'rxjs';

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CustomTranslatePipe,
    ReactiveFormsModule,
    RouterLink,
    SearchInputComponent,
    SortSwitcherComponent,
  ],
})
export class ArticlesListComponent implements OnInit, OnDestroy {
  public articlesList = toSignal(this.store.select(selectUserMappedArticles));

  public isAlphabetSort = false;

  public searchInput = new UntypedFormControl();

  private searchValue: string = '';

  private sortKey: Sort;

  private sortKey$ = this.store.select(selectSortKey);

  constructor(
    private actions$: Actions,
    private destroyRef: DestroyRef,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.initArticlesList();
  }

  public ngOnDestroy(): void {
    this.saveSearchState();
    this.store.dispatch(unsetArticles());
  }

  public searchCleared(): void {
    this.searchInput.setValue('');
  }

  private initArticlesList(): void {
    this.watchSortAndSearchChanges();
    this.actions$
      .pipe(
        ofType(getArticlesSuccess, getArticlesFailure),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.sortAndSearchArticles();
      });

    this.route.params
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(params => {
        this.store.dispatch(
          articlesListComponentInitialized({
            categoryUuid: params['categoryUuid'],
          })
        );
      });
  }

  private watchSortAndSearchChanges(): void {
    combineLatest([this.sortKey$, this.searchInput.valueChanges])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([sortKey, searchValue]) => {
        this.searchValue = searchValue;
        this.sortKey = sortKey;
        this.isAlphabetSort = this.sortKey !== Sort.Priority;

        this.sortAndSearchArticles();
      });

    this.store
      .select(selectArticlesSearchState)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(searchState => {
        this.searchInput.setValue(searchState);
      });
  }

  private sortAndSearchArticles(): void {
    this.store.dispatch(
      sortAndSearchArticles({
        sortType: this.sortKey,
        searchValue: this.searchValue,
      })
    );
  }

  private saveSearchState(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(event => {
        const e = event as NavigationEnd;
        const searchState = ARTICLE_VIEW_URL_REGEXP.test(e.url)
          ? this.searchInput.value
          : '';

        this.store.dispatch(setArticlesSearchState({ searchState }));
      });
  }
}
