import { ROUTING_MAP } from '@app/shared/models/constants/routing-map';
import { Metadata } from '@app/shared/models/interfaces/metadata.interface';

export const routesMetadata: Record<string, Metadata> = {
  [ROUTING_MAP.index]: {
    title: $localize`VOKA Anatomy Pro - Полный атлас анатомии и патологий в 3D`,
    meta: [
      {
        property: 'og:title',
        content: $localize`VOKA Anatomy Pro - Полный атлас анатомии и патологий в 3D`,
      },
      {
        property: 'og:description',
        content: $localize`Изучите человеческую анатомию и патологию с VOKA Anatomy Pro, подробный 3D-атлас, идеальный для образования и профессионалов.`,
      },
    ],
  },
  [ROUTING_MAP.articles]: {
    title: $localize`Статьи анатомии и патологий - VOKA Anatomy Pro`,
    meta: [
      {
        property: 'og:title',
        content: $localize`Статьи анатомии и патологий - VOKA Anatomy Pro`,
      },
      {
        property: 'og:description',
        content: $localize`Статьи по анатомии и патологиям для здравоохранения и медицинского образования.`,
      },
    ],
  },
  [ROUTING_MAP.collection.index]: {
    title: $localize`Моя коллекция - VOKA Anatomy Pro`,
  },
  [ROUTING_MAP.anatomy]: {
    title: $localize`Атлас человеческой анатомии в 3D | VOKA`,
    meta: [
      {
        property: 'og:title',
        content: $localize`Атлас человеческой анатомии в 3D | VOKA`,
      },
      {
        property: 'og:description',
        content: $localize`Используйте обширный онлайн-атлас анатомии в 3D для подробного изучения и исследования. Раскройте тонкости строения человеческого организма с помощью нашего современного 3D атласа.`,
      },
    ],
  },
  [ROUTING_MAP.pathology]: {
    title: $localize`Категории патологий | Исследуйте патологии человека с VOKA`,
    meta: [
      {
        property: 'og:title',
        content: $localize`Категории патологий | Исследуйте патологии человека с VOKA`,
      },
      {
        property: 'og:description',
        content: $localize`Изучите категории патологий онлайн с помощью VOKA 3D - Глубокое погружение в мир патологии человека с использованием нашей инновационной 3D платформы от VOKA.`,
      },
    ],
  },
  [ROUTING_MAP.termsOfUse]: {
    title: $localize`Условия использования VOKA Anatomy Pro`,
    meta: [
      {
        property: 'og:title',
        content: $localize`Условия использования VOKA Anatomy Pro`,
      },
      {
        property: 'og:description',
        content: $localize`Настоящее Пользовательское соглашение распространяется на приложение VOKA Anatomy Pro, и заключается между Innowise Group и Вами, нашим пользователем, далее именуемым как «Пользователи» или «Вы».`,
      },
    ],
  },
  [ROUTING_MAP.privacy]: {
    title: $localize`Политика приватности для VOKA Anatomy Pro`,
    meta: [
      {
        property: 'og:title',
        content: $localize`Политика приватности для VOKA Anatomy Pro`,
      },
      {
        property: 'og:description',
        content: $localize`Устанавливая, используя, регистрируясь или иным образом получая доступ к нашим услугам, вы соглашаетесь с данной политикой конфиденциальности и предоставляете информированное и явно выраженное согласие об обработке вашей персональной информации в соответствии с данной политикой конфиденциальности.`,
      },
    ],
  },
  [ROUTING_MAP.bestAnatomyApp]: {
    title: 'Best Anatomy Apps in 2024',
    meta: [
      {
        property: 'og:title',
        content: 'Best Anatomy Apps in 2024',
      },
      {
        property: 'og:description',
        content:
          'Explore the best anatomy apps in 2024 that offer immersive 3D models and interactive features for an engaging learning experience',
      },
      {
        property: 'description',
        content:
          'Explore the best anatomy apps in 2024 that offer immersive 3D models and interactive features for an engaging learning experience',
      },
    ],
  },
  [ROUTING_MAP.helpCenter]: {
    title: $localize`Центр помощи - VOKA Anatomy Pro`,
    meta: [
      {
        property: 'og:title',
        content: $localize`Центр помощи - VOKA Anatomy Pro`,
      },
      {
        property: 'og:description',
        content: $localize`Изучите человеческую анатомию и патологию с VOKA Anatomy Pro, подробный 3D-атлас, идеальный для образования и профессионалов.`,
      },
    ],
  },
  [ROUTING_MAP.profile.index]: {
    title: $localize`Мой профиль - VOKA Anatomy Pro`,
    meta: [
      {
        property: 'og:title',
        content: $localize`Мой профиль - VOKA Anatomy Pro`,
      },
    ],
  },
  [ROUTING_MAP.profile.changePassword]: {
    title: $localize`Изменить пароль - VOKA Anatomy Pro`,
    meta: [
      {
        property: 'og:title',
        content: $localize`Изменить пароль - VOKA Anatomy Pro`,
      },
    ],
  },
};
