<div
  class="card"
  [class.phone]="mobileDeviceService.isMobileDevice"
  [class.card_locked]="!purchased"
  (click)="openModelViewPage()">
  @if (!purchased) {
    <svg-icon class="card__lock" key="no-purchase-lock"></svg-icon>
  }

  @if (inProgress) {
    <div i18n class="in-progress">В разработке</div>
    <div class="in-progress-blur"></div>
  }

  <div class="card__sub-title">
    <div class="card__mark">
      <ng-container *ngTemplateOutlet="markRef"></ng-container>
    </div>
    <span class="category-title">
      <ng-container *ngTemplateOutlet="categoryTitleRef"></ng-container>
    </span>
    <span class="pathology-title">
      <ng-container *ngTemplateOutlet="pathologyTitleRef"></ng-container>
    </span>
  </div>
  <div class="card__title" [class.models-title]="cardContent.modelsNum">
    <span class="pathology-type-title">
      <ng-container *ngTemplateOutlet="pathologyTypeTitleRef"></ng-container>
    </span>
    <span class="model-title">
      <ng-container *ngTemplateOutlet="modelTitleRef"></ng-container>
    </span>
    @if (cardContent.modelsNum) {
      <span class="models">
        {{
          cardContent.modelsNum
            | numeralsAdaptiveText: 'model' : currentLanguage
        }}
      </span>
    }
  </div>

  <div class="card__background">
    <img class="img" [src]="cardContent?.imgUrl" appImageNotFound />
  </div>
</div>
