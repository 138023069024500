import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, catchError, throwError } from 'rxjs';

import { ROUTING_MAP } from '../../models/constants/routing-map';
import { ServerErrorsService } from '../../services/server-errors/server-errors.service';
import { checkRouteExcluded } from '../check-route-excluded.util';
import { ExcludedRoute } from '../excluded-routes.interface';

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
  private excludedRoutes: ExcludedRoute[] = [
    {
      url: '/payment/chargebee',
      method: 'post',
    },
    {
      url: '/users/check',
      method: 'post',
    },
    {
      url: '/web/user',
      method: 'get',
    },
  ];

  constructor(
    private router: Router,
    private serverErrorsService: ServerErrorsService
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (checkRouteExcluded(request, this.excludedRoutes)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (/50[0-4]/.test(String(error.status))) {
          if (request.method === 'GET') {
            this.serverErrorsService.setIsServerErrorPage(true);
            this.router.navigate([ROUTING_MAP.serverError]);
          } else {
            this.serverErrorsService.setServerErrorPopupStatus(true);
          }

          return EMPTY;
        }
        return throwError(() => error);
      })
    );
  }
}
