<div class="page-wrapper">
  <h3 class="t-heading-3 t-bold" i18n>Личная информация</h3>
  <form
    [formGroup]="personalInformationForm"
    (ngSubmit)="save()"
    class="personal-information">
    <div class="form">
      <div class="half-width">
        <app-input
          [maxlength]="maxLength.small"
          [errorsMap]="{
            required: 'first-name',
          }"
          formControlName="firstName"
          i18n-label
          label="Имя"
          [markedAsRequired]="true">
        </app-input>
        <app-input
          [maxlength]="maxLength.small"
          [errorsMap]="{
            required: 'last-name',
          }"
          formControlName="lastName"
          i18n-label
          label="Фамилия"
          [markedAsRequired]="true">
        </app-input>
        <app-autocomplete
          formControlName="country"
          [errorsMap]="{
            required: 'country',
            include: 'country',
          }"
          i18n-label
          label="Страна"
          [options]="countriesService.localizedCountriesList"
          labelField="name">
          <ng-template #item let-option>
            <div class="country">
              @if (option.image; as flag) {
                <div class="flag-icon-wrapper">
                  <img alt="flag" class="flag-icon" [src]="flag" />
                </div>
              }

              {{ option.name }}
            </div>
          </ng-template>
        </app-autocomplete>

        <app-input
          [maxlength]="maxLength.large"
          formControlName="city"
          i18n-label
          label="Город">
        </app-input>
        <app-dropdown
          formControlName="participateAs"
          i18n-label
          label="Деятельность"
          [options]="roles"
          labelField="label"
          valueField="value"
          [markedAsRequired]="true"
          (lastOptionChosen)="optionSelect($event)">
          <ng-template #item let-option>
            {{ option['label'] }}
          </ng-template>
        </app-dropdown>

        <app-input
          [maxlength]="maxLength.medium"
          formControlName="specialty"
          i18n-label
          label="Специальность">
        </app-input>
      </div>
      @if (isLastSelect) {
        <div class="full-width">
          <app-input
            formControlName="participateAsDescription"
            appInputCapitalize
            [maxlength]="maxLength.medium"
            i18n-label
            label="Пожалуйста, укажите род деятельности">
          </app-input>
        </div>
      }

      <div class="half-width">
        <app-input
          formControlName="position"
          i18n-label
          label="Должность"
          [maxlength]="maxLength.medium">
        </app-input>
        <app-input
          formControlName="phoneNumber"
          i18n-label
          label="Телефон"
          [maxlength]="maxLength.small"
          (keypress)="phoneMask($event)">
        </app-input>
      </div>
      <div class="full-width">
        <app-input
          [maxlength]="maxLength.veryLarge"
          formControlName="socialNetworkLink"
          i18n-label
          label="Ссылка на социальную сеть">
        </app-input>
        <app-input
          [maxlength]="maxLength.veryLarge"
          formControlName="experience"
          i18n-label
          label="Ваш профессиональный опыт">
        </app-input>
      </div>
    </div>

    <div class="button-container">
      <button
        appButton
        type="submit"
        [disabled]="disabledButton"
        [loading]="isSaving | async"
        i18n>
        Сохранить изменения
      </button>
      <button appButton class="outlined" (click)="reset()" i18n>
        Отменить
      </button>
    </div>
  </form>
</div>
