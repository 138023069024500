import * as i0 from '@angular/core';
import { EventEmitter, Directive, Input, Output, ContentChild, Component, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';

// Equality comparison for objects
const _c0 = ["cbProvider", ""];
const _c1 = ["*"];
function Provider_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵprojection(1);
    i0.ɵɵelementContainerEnd();
  }
}
function isEqual(left, right) {
  const OBJECT_STRING = '[object Object]';
  if (typeof left !== 'object' || typeof right !== 'object') {
    return left === right;
  }
  if (left === null || right === null) {
    return left === right;
  }
  const leftArray = Array.isArray(left);
  const rightArray = Array.isArray(right);
  if (leftArray !== rightArray) {
    return false;
  }
  const leftPlainObject = Object.prototype.toString.call(left) === OBJECT_STRING;
  const rightPlainObject = Object.prototype.toString.call(right) === OBJECT_STRING;
  if (leftPlainObject !== rightPlainObject) {
    return false;
  }
  if (!leftPlainObject && !leftArray) {
    return false;
  }
  const leftKeys = Object.keys(left);
  const rightKeys = Object.keys(right);
  if (leftKeys.length !== rightKeys.length) {
    return false;
  }
  const keySet = {};
  for (const key of leftKeys) {
    keySet[key] = true;
  }
  for (const key of rightKeys) {
    keySet[key] = true;
  }
  const allKeys = Object.keys(keySet);
  if (allKeys.length !== leftKeys.length) {
    return false;
  }
  const l = left;
  const r = right;
  const pred = key => {
    return isEqual(l[key], r[key]);
  };
  return allKeys.every(pred);
}
function getPropChanges(changes, props) {
  const changedProps = Object.keys(changes).filter(key => props.indexOf(key) >= 0);
  const prevOptions = {};
  const currentOptions = {};
  changedProps.map(prop => {
    const change = changes[prop];
    if (prop === 'styles') {
      prop = 'style';
    }
    prevOptions[prop] = change.previousValue;
    currentOptions[prop] = change.currentValue;
  });
  return {
    hasChanged: !isEqual(prevOptions, currentOptions),
    currentOptions,
    prevOptions
  };
}
function validateCbInstance(cbInstance) {
  if (cbInstance != null) {
    const site = cbInstance.site;
    const key = cbInstance.publishableKey;
    if (!(site != null && typeof site == "string" && site.length > 0)) return false;
    if (!(key != null && typeof key == "string" && key.length > 0)) return false;
    return true;
  } else return false;
}
class NumberFieldDirective {
  styles;
  placeholder;
  ready = new EventEmitter();
  focus = new EventEmitter();
  blur = new EventEmitter();
  change = new EventEmitter();
  id = '';
  field = null;
  type = 'number';
  constructor(el) {
    if (el.nativeElement) {
      this.id = el.nativeElement.id;
    }
  }
  onFocus = status => {
    this.focus.emit(status);
  };
  onBlur = status => {
    this.blur.emit(status);
  };
  onReady = el => {
    this.ready.emit(el);
  };
  onChange = status => {
    this.change.emit(status);
  };
  ngOnChanges(changes) {
    if (this.field) {
      const props = ['placeholder', 'styles'];
      const {
        hasChanged,
        currentOptions
      } = getPropChanges(changes, props);
      if (hasChanged) {
        this.field.update(currentOptions);
      }
    }
  }
  /** @nocollapse */
  static ɵfac = function NumberFieldDirective_Factory(t) {
    return new (t || NumberFieldDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: NumberFieldDirective,
    selectors: [["", "cbNumberField", ""]],
    inputs: {
      styles: "styles",
      placeholder: "placeholder"
    },
    outputs: {
      ready: "ready",
      focus: "focus",
      blur: "blur",
      change: "change"
    },
    features: [i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumberFieldDirective, [{
    type: Directive,
    args: [{
      selector: '[cbNumberField]'
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    styles: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    ready: [{
      type: Output
    }],
    focus: [{
      type: Output
    }],
    blur: [{
      type: Output
    }],
    change: [{
      type: Output
    }]
  });
})();
class ExpiryFieldDirective {
  cbComponent;
  styles;
  placeholder;
  ready = new EventEmitter();
  focus = new EventEmitter();
  blur = new EventEmitter();
  change = new EventEmitter();
  id = '';
  field = null;
  type = 'expiry';
  constructor(el) {
    if (el.nativeElement) {
      this.id = el.nativeElement.id;
    }
  }
  onFocus = status => {
    this.focus.emit(status);
  };
  onBlur = status => {
    this.blur.emit(status);
  };
  onReady = el => {
    this.ready.emit(el);
  };
  onChange = status => {
    this.change.emit(status);
  };
  ngOnChanges(changes) {
    if (this.field) {
      const props = ['placeholder', 'styles'];
      const {
        hasChanged,
        currentOptions
      } = getPropChanges(changes, props);
      if (hasChanged) {
        this.field.update(currentOptions);
      }
    }
  }
  /** @nocollapse */
  static ɵfac = function ExpiryFieldDirective_Factory(t) {
    return new (t || ExpiryFieldDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: ExpiryFieldDirective,
    selectors: [["", "cbExpiryField", ""]],
    inputs: {
      cbComponent: "cbComponent",
      styles: "styles",
      placeholder: "placeholder"
    },
    outputs: {
      ready: "ready",
      focus: "focus",
      blur: "blur",
      change: "change"
    },
    features: [i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ExpiryFieldDirective, [{
    type: Directive,
    args: [{
      selector: '[cbExpiryField]'
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    cbComponent: [{
      type: Input
    }],
    styles: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    ready: [{
      type: Output
    }],
    focus: [{
      type: Output
    }],
    blur: [{
      type: Output
    }],
    change: [{
      type: Output
    }]
  });
})();
class CvvFieldDirective {
  cbComponent = null;
  styles;
  placeholder;
  ready = new EventEmitter();
  focus = new EventEmitter();
  blur = new EventEmitter();
  change = new EventEmitter();
  id = '';
  field = null;
  type = 'cvv';
  constructor(el) {
    if (el.nativeElement) {
      this.id = el.nativeElement.id;
    }
  }
  onFocus = status => {
    this.focus.emit(status);
  };
  onBlur = status => {
    this.blur.emit(status);
  };
  onReady = el => {
    this.ready.emit(el);
  };
  onChange = status => {
    this.change.emit(status);
  };
  ngOnChanges(changes) {
    if (this.field) {
      const props = ['placeholder', 'styles'];
      const {
        hasChanged,
        currentOptions
      } = getPropChanges(changes, props);
      if (hasChanged) {
        this.field.update(currentOptions);
      }
    }
  }
  /** @nocollapse */
  static ɵfac = function CvvFieldDirective_Factory(t) {
    return new (t || CvvFieldDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: CvvFieldDirective,
    selectors: [["", "cbCvvField", ""]],
    inputs: {
      styles: "styles",
      placeholder: "placeholder"
    },
    outputs: {
      ready: "ready",
      focus: "focus",
      blur: "blur",
      change: "change"
    },
    features: [i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CvvFieldDirective, [{
    type: Directive,
    args: [{
      selector: '[cbCvvField]'
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    styles: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    ready: [{
      type: Output
    }],
    focus: [{
      type: Output
    }],
    blur: [{
      type: Output
    }],
    change: [{
      type: Output
    }]
  });
})();
class CardFieldDirective {
  id = "";
  cbInstance = null;
  cbComponent = null;
  icon;
  classes;
  fonts;
  styles;
  locale;
  showTestCards;
  currency;
  placeholder;
  numberComponent;
  expiryComponent;
  cvvComponent;
  // Below events only for combined-field
  ready = new EventEmitter();
  focus = new EventEmitter();
  blur = new EventEmitter();
  change = new EventEmitter();
  load;
  initialization;
  constructor(el) {
    if (el.nativeElement) {
      this.id = el.nativeElement.id;
    }
  }
  onReady = (cardComponent, field) => {
    let data;
    if (field) {
      // Emit allows only one argument (Spec deviation)
      data = {
        cardComponent,
        field
      };
    } else {
      data = cardComponent;
    }
    this.ready.emit(data);
  };
  // Below events only for Combined field
  onFocus = status => {
    this.focus.emit(status);
  };
  onBlur = status => {
    this.blur.emit(status);
  };
  onChange = status => {
    this.change.emit(status);
  };
  ngOnInit() {
    if (typeof window !== "undefined" && typeof window["Chargebee"] !== "undefined") {
      const options = {
        icon: typeof this.icon === "boolean" ? this.icon : true,
        fonts: this.fonts || [],
        style: this.styles || {},
        locale: this.locale || "en",
        showTestCards: this.showTestCards ?? false,
        classes: this.classes || {},
        currency: this.currency || "USD",
        placeholder: this.placeholder || {}
      };
      this.cbInstance = window["Chargebee"].getInstance();
      this.cbInstance.load("components").then(() => {
        this.cbComponent = this.cbInstance.createComponent("card", options);
        // Attaching listeners if any (only applicable for combined field)
        this.cbComponent.on("ready", this.onReady);
        this.cbComponent.on("focus", this.onFocus);
        this.cbComponent.on("blur", this.onBlur);
        this.cbComponent.on("change", this.onChange);
        // Initialize inidividual fields (if present)
        this.initializeField(this.cbComponent, this.numberComponent);
        this.initializeField(this.cbComponent, this.expiryComponent);
        this.initializeField(this.cbComponent, this.cvvComponent);
        this.cbComponent.mount(`#${this.id}`);
      });
    }
  }
  initializeField(cbComponent, fieldElement) {
    if (cbComponent && fieldElement) {
      const fieldInstance = cbComponent.createField(fieldElement.type, {
        style: fieldElement.styles || {},
        placeholder: fieldElement.placeholder || ""
      }).at(`#${fieldElement.id}`);
      fieldElement.field = fieldInstance;
      // attach listeners
      fieldInstance.on("ready", fieldElement.onReady);
      fieldInstance.on("focus", fieldElement.onFocus);
      fieldInstance.on("blur", fieldElement.onBlur);
      fieldInstance.on("change", fieldElement.onChange);
      return fieldInstance;
    }
    return null;
  }
  tokenize(additionalData) {
    return this.cbComponent.tokenize(additionalData);
  }
  authorizeWith3ds(paymentIntent, additionalData, callbacks) {
    return this.cbComponent.authorizeWith3ds(paymentIntent, additionalData, callbacks);
  }
  ngOnChanges(changes) {
    if (this.cbComponent) {
      const props = ["icon", "classes", "fonts", "locale", "showTestCards", "styles", "placeholder"];
      const {
        currentOptions,
        hasChanged
      } = getPropChanges(changes, props);
      if (hasChanged) {
        this.cbComponent.update(currentOptions);
      }
    }
  }
  /** @nocollapse */
  static ɵfac = function CardFieldDirective_Factory(t) {
    return new (t || CardFieldDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: CardFieldDirective,
    selectors: [["", "cbCardField", ""]],
    contentQueries: function CardFieldDirective_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, NumberFieldDirective, 7);
        i0.ɵɵcontentQuery(dirIndex, ExpiryFieldDirective, 7);
        i0.ɵɵcontentQuery(dirIndex, CvvFieldDirective, 7);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.numberComponent = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.expiryComponent = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.cvvComponent = _t.first);
      }
    },
    inputs: {
      icon: "icon",
      classes: "classes",
      fonts: "fonts",
      styles: "styles",
      locale: "locale",
      showTestCards: "showTestCards",
      currency: "currency",
      placeholder: "placeholder"
    },
    outputs: {
      ready: "ready",
      focus: "focus",
      blur: "blur",
      change: "change"
    },
    features: [i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CardFieldDirective, [{
    type: Directive,
    args: [{
      selector: "[cbCardField]"
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    icon: [{
      type: Input
    }],
    classes: [{
      type: Input
    }],
    fonts: [{
      type: Input
    }],
    styles: [{
      type: Input
    }],
    locale: [{
      type: Input
    }],
    showTestCards: [{
      type: Input
    }],
    currency: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    numberComponent: [{
      type: ContentChild,
      args: [NumberFieldDirective, {
        static: true
      }]
    }],
    expiryComponent: [{
      type: ContentChild,
      args: [ExpiryFieldDirective, {
        static: true
      }]
    }],
    cvvComponent: [{
      type: ContentChild,
      args: [CvvFieldDirective, {
        static: true
      }]
    }],
    ready: [{
      type: Output
    }],
    focus: [{
      type: Output
    }],
    blur: [{
      type: Output
    }],
    change: [{
      type: Output
    }]
  });
})();
class Provider {
  cbInstance;
  validated = false;
  constructor() {}
  ngOnChanges(changes) {
    if (validateCbInstance(this.cbInstance)) this.validated = true;else {
      this.validated = false;
    }
  }
  /** @nocollapse */
  static ɵfac = function Provider_Factory(t) {
    return new (t || Provider)();
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: Provider,
    selectors: [["", "cbProvider", ""]],
    inputs: {
      cbInstance: "cbInstance"
    },
    features: [i0.ɵɵNgOnChangesFeature],
    attrs: _c0,
    ngContentSelectors: _c1,
    decls: 1,
    vars: 1,
    consts: [[4, "ngIf"]],
    template: function Provider_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵtemplate(0, Provider_ng_container_0_Template, 2, 0, "ng-container", 0);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.validated);
      }
    },
    dependencies: [i1.NgIf],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Provider, [{
    type: Component,
    args: [{
      selector: '[cbProvider]',
      template: `
        <ng-container *ngIf="validated">
            <ng-content>
            </ng-content>
        </ng-container>
    `
    }]
  }], () => [], {
    cbInstance: [{
      type: Input
    }]
  });
})();
class ChargebeeJsAngularWrapperModule {
  /** @nocollapse */static ɵfac = function ChargebeeJsAngularWrapperModule_Factory(t) {
    return new (t || ChargebeeJsAngularWrapperModule)();
  };
  /** @nocollapse */
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ChargebeeJsAngularWrapperModule
  });
  /** @nocollapse */
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChargebeeJsAngularWrapperModule, [{
    type: NgModule,
    args: [{
      declarations: [CardFieldDirective, CvvFieldDirective, NumberFieldDirective, ExpiryFieldDirective, Provider],
      imports: [CommonModule],
      exports: [CardFieldDirective, CvvFieldDirective, NumberFieldDirective, ExpiryFieldDirective, Provider]
    }]
  }], null, null);
})();

/*
 * Public API Surface of chargebee-js-angular-wrapper
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CardFieldDirective, ChargebeeJsAngularWrapperModule, CvvFieldDirective, ExpiryFieldDirective, NumberFieldDirective, Provider };
