import { CanDeactivateFn } from '@angular/router';

import { CanDeactivateComponent } from '../models/interfaces/can-deactivate-component.interface';

export const deactivateGuard: CanDeactivateFn<CanDeactivateComponent> = (
  component,
  _currentRoute,
  _currentState
) => {
  return component.canDeactivate();
};
