import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';

import { ROUTING_MAP } from '../../models/constants/routing-map';
import { checkRouteExcluded } from '../check-route-excluded.util';
import { ExcludedRoute } from '../excluded-routes.interface';

@Injectable()
export class NotFoundInterceptor implements HttpInterceptor {
  private excludedRoutes: ExcludedRoute[] = [];

  constructor(private router: Router) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (checkRouteExcluded(request, this.excludedRoutes)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.router.navigate([ROUTING_MAP.notFound]);
          return EMPTY;
        }
        return throwError(() => error);
      })
    );
  }
}
