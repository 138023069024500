@for (alert of alertService.activeAlerts$ | async; track $index) {
  <ng-container
    [ngTemplateOutlet]="alertTemplate"
    [ngTemplateOutletContext]="{ $implicit: alert }"></ng-container>
}

<ng-template #alertTemplate let-alert>
  <div class="notification">
    @if (alert.status === 'error') {
      <svg-icon class="icon" key="error"></svg-icon>
    }
    @if (alert.status === 'success') {
      <svg-icon class="icon" key="success"></svg-icon>
    }

    <div class="content">
      @if (alert.title) {
        <span class="t-subtitle t-bold title">
          @if (alert.title) {
            {{ alert.title }}
          } @else {
            <ng-container i18n> Что-то пошло не так </ng-container>
          }
        </span>
      }

      @if (alert.message) {
        <p class="t-medium message">
          {{ alert.message }}
        </p>
      }
    </div>

    <app-close-button
      class="close"
      (click)="alertService.deleteAlert(alert.id)"></app-close-button>
  </div>
</ng-template>
