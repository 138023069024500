import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { selectUserInfo } from '@store/user/user.selectors';
import { Observable, iif, of, switchMap } from 'rxjs';
import { ROUTING_MAP } from '../models/constants/routing-map';
import { RedirectService } from '../services/redirect.service';

export const authGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> => {
  const redirectService = inject(RedirectService);
  const router = inject(Router);
  const store = inject(Store);

  redirectService.initialNavigation =
    router.getCurrentNavigation().previousNavigation === null;

  redirectService.setOriginalUrl(state.url.split('?')[0]);
  return store.select(selectUserInfo).pipe(
    switchMap(user => {
      return iif(
        () => Boolean(user),
        of(true),
        of(router.createUrlTree([ROUTING_MAP.noAccess]))
      );
    })
  );
};
