import { Routes } from '@angular/router';
import { articlesRoutes } from '@app/pages/articles/articles.routes';
import { routesMetadata } from '@app/shared/models/constants/routes-metadata';
import { authGuard } from 'src/app/shared/guards/auth.guard';
import { bundlePageMetaGuard } from 'src/app/shared/guards/bundle-page-meta.guard';
import { checkUuidGuard } from 'src/app/shared/guards/check-uuid/check-uuid.guard';
import { deactivateGuard } from 'src/app/shared/guards/deactivate.guard';
import { serverErrorsGuard } from 'src/app/shared/guards/server-errors/server-errors.guard';
import { getPathologyByCategoryUuidResolver } from 'src/app/shared/resolvers/get-pathology-by-category-uuid.resolver';
import { pageMetaGuard } from '../../shared/guards/page-meta.guard';
import { ROUTING_MAP } from '../../shared/models/constants/routing-map';
import { collectionRoutes } from '../collection/collection.routes';
import { PROFILE_ROUTES } from '../profile/profile-routes';

export const mainRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('../landing/landing.component').then(_ => _.LandingComponent),
    canActivate: [pageMetaGuard],
    data: { ...routesMetadata[ROUTING_MAP.index] },
  },
  {
    path: ROUTING_MAP.pathology,
    loadComponent: () =>
      import('../categories-list/categories-list.component').then(
        _ => _.CategoriesListComponent
      ),
    canActivate: [pageMetaGuard],
    data: { ...routesMetadata[ROUTING_MAP.pathology] },
  },
  {
    path: `${ROUTING_MAP.pathology}/:uuid`,
    loadChildren: () =>
      import('../categories/categories.routes').then(_ => _.categoriesRoutes),
    canActivate: [checkUuidGuard],
    resolve: {
      category: getPathologyByCategoryUuidResolver,
    },
  },
  {
    path: ROUTING_MAP.anatomy,
    loadComponent: () =>
      import('../categories-list/categories-list.component').then(
        _ => _.CategoriesListComponent
      ),
    canActivate: [pageMetaGuard],
    data: { ...routesMetadata[ROUTING_MAP.anatomy] },
  },
  {
    path: `${ROUTING_MAP.anatomy}/:uuid`,
    loadChildren: () =>
      import('../categories/categories.routes').then(_ => _.categoriesRoutes),
    canActivate: [checkUuidGuard],
    resolve: {
      category: getPathologyByCategoryUuidResolver,
    },
  },
  {
    path: ROUTING_MAP.pricing,
    loadComponent: () =>
      import('../pricing/pricing.component').then(_ => _.PricingComponent),
    canActivate: [pageMetaGuard],
    data: { ...routesMetadata[ROUTING_MAP.index] },
  },
  {
    path: ROUTING_MAP.contactUs,
    loadComponent: () =>
      import('../contact-us/contact-us.component').then(
        _ => _.ContactUsComponent
      ),
    canDeactivate: [deactivateGuard],
    canActivate: [pageMetaGuard],
    data: { ...routesMetadata[ROUTING_MAP.index] },
  },
  {
    path: ROUTING_MAP.profile.index,
    loadComponent: () =>
      import('../profile/profile.component').then(_ => _.ProfileComponent),
    children: PROFILE_ROUTES,
    canActivate: [authGuard],
  },
  {
    path: ROUTING_MAP.models,
    loadChildren: () =>
      import('../bundle/bundle.routes').then(_ => _.bundleRoutes),
    canActivate: [authGuard, bundlePageMetaGuard],
  },
  {
    path: ROUTING_MAP.collection.index,
    loadComponent: () =>
      import('../collection/collection.component').then(
        _ => _.CollectionComponent
      ),
    canActivate: [authGuard, pageMetaGuard],
    children: collectionRoutes,
    data: { ...routesMetadata[ROUTING_MAP.collection.index] },
  },
  {
    path: ROUTING_MAP.noAccess,
    loadComponent: () =>
      import('../no-access-page/no-access-page.component').then(
        _ => _.NoAccessPageComponent
      ),
    canActivate: [pageMetaGuard],
    data: { ...routesMetadata[ROUTING_MAP.index] },
  },
  {
    path: ROUTING_MAP.privacy,
    loadComponent: () =>
      import('../privacy-policy/privacy-policy.component').then(
        _ => _.PrivacyPolicyComponent
      ),
    canActivate: [pageMetaGuard],
    data: {
      ...routesMetadata[ROUTING_MAP.privacy],
    },
  },
  {
    path: ROUTING_MAP.termsOfUse,
    loadComponent: () =>
      import('../terms-of-use/terms-of-use.component').then(
        m => m.TermsOfUseComponent
      ),
    canActivate: [pageMetaGuard],
    data: {
      ...routesMetadata[ROUTING_MAP.termsOfUse],
    },
  },
  {
    path: ROUTING_MAP.notFound,
    loadComponent: () =>
      import('../page-not-found/page-not-found.component').then(
        _ => _.PageNotFoundComponent
      ),
    canActivate: [pageMetaGuard],
    data: { ...routesMetadata[ROUTING_MAP.index] },
  },
  {
    path: ROUTING_MAP.helpCenter,
    loadComponent: () =>
      import('../help-center/help-center.component').then(
        _ => _.HelpCenterComponent
      ),
    canDeactivate: [deactivateGuard],
    canActivate: [pageMetaGuard],
    data: {
      ...routesMetadata[ROUTING_MAP.helpCenter],
    },
  },
  {
    path: ROUTING_MAP.bestAnatomyApp,
    loadComponent: () =>
      import('../best-anatomy-app/best-anatomy-app.component').then(
        _ => _.BestAnatomyAppComponent
      ),
    canActivate: [pageMetaGuard],
    data: { ...routesMetadata[ROUTING_MAP.bestAnatomyApp] },
  },
  {
    path: ROUTING_MAP.serverError,
    loadComponent: () =>
      import('../server-errors/server-errors.component').then(
        _ => _.ServerErrorsComponent
      ),
    canActivate: [serverErrorsGuard, pageMetaGuard],
    data: { ...routesMetadata[ROUTING_MAP.index] },
  },
  {
    path: ROUTING_MAP.articles,
    loadComponent: () =>
      import('../articles/articles.component').then(_ => _.ArticlesComponent),
    children: articlesRoutes,
  },
  {
    path: '**',
    redirectTo: ROUTING_MAP.notFound,
  },
];
