<h3 class="t-heading-3 heading t-bold" i18n>Настройки темы</h3>

<p class="description" i18n>
  Выберите как будет выглядеть интерфейс VOKA Anatomy Pro для этого устройства.
  При выборе Автонастройки платформа будет автоматически использовать тему
  вашего устройства.
</p>

@if (themeService.currentTheme(); as currentTheme) {
  <div class="themes">
    @for (theme of THEMES; track $index) {
      <div
        class="theme"
        [class.theme_active]="theme.theme === currentTheme"
        (click)="setTheme(theme.theme)">
        <img
          class="theme__icon"
          [src]="theme.imageUrl"
          alt="Theme image icon" />
        <span>{{ theme.text }}</span>
      </div>
    }
  </div>
}
