import { HttpRequest } from '@angular/common/http';

import { ExcludedRoute } from './excluded-routes.interface';

export const checkRouteExcluded = (
  request: HttpRequest<unknown>,
  excludedRoutes: ExcludedRoute[]
): boolean => {
  return Boolean(
    excludedRoutes.find(
      route =>
        route.method === request.method.toLowerCase() &&
        request.url.includes(route.url)
    )
  );
};
