import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class PaymentApiService {
  private http = inject(HttpClient);

  public purchase(paymentData: {
    productId: string;
    tokenId: string;
  }): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.apiUrl}/payment/chargebee`,
      paymentData
    );
  }

  public cancelSubscription(data: {
    productId: string;
  }): Observable<{ message: string; title: string }> {
    return this.http.delete<{ message: string; title: string }>(
      `${environment.apiUrl}/payment`,
      {
        params: {
          productId: data.productId,
        },
      }
    );
  }
}
