<div class="page-wrapper">
  <h3 class="t-heading-3 t-bold" i18n>Изменить пароль</h3>

  <form
    [formGroup]="changePasswordForm"
    class="change-password"
    (ngSubmit)="save()">
    <div class="form">
      <app-input
        i18n-label
        label="Действующий пароль"
        formControlName="oldPass"
        type="password"
        [errorsMap]="{
          required: 'password',
          length: 'password',
        }"
        [markedAsRequired]="true">
      </app-input>

      <app-input
        i18n-label
        label="Новый пароль"
        formControlName="newPass"
        type="password"
        [errorsMap]="{
          required: 'password',
          length: 'password',
        }"
        [markedAsRequired]="true">
      </app-input>

      <app-input
        i18n-label
        label="Подтвердите новый пароль"
        formControlName="confirmNewPass"
        type="password"
        [errorsMap]="{
          required: 'confirm-password',
          match: 'confirm-password',
          length: 'confirm-password',
        }"
        [markedAsRequired]="true">
      </app-input>
    </div>

    <button appButton type="submit" [loading]="isPasswordChanging" i18n>
      Сохранить изменения
    </button>
  </form>
</div>
