import { Routes } from '@angular/router';

import { pageMetaGuard } from '@app/shared/guards/page-meta.guard';
import { routesMetadata } from '@app/shared/models/constants/routes-metadata';
import { ROUTING_MAP } from '../../shared/models/constants/routing-map';

export const mobileRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () =>
      import('./mobile-device-stub.component').then(
        _ => _.MobileDeviceStubComponent
      ),
  },
  {
    path: ROUTING_MAP.termsOfUse,
    loadComponent: () =>
      import('../terms-of-use/terms-of-use.component').then(
        _ => _.TermsOfUseComponent
      ),
    canActivate: [pageMetaGuard],
    data: { ...routesMetadata[ROUTING_MAP.termsOfUse] },
  },
  {
    path: ROUTING_MAP.privacy,
    loadComponent: () =>
      import('../privacy-policy/privacy-policy.component').then(
        _ => _.PrivacyPolicyComponent
      ),
    canActivate: [pageMetaGuard],
    data: { ...routesMetadata[ROUTING_MAP.privacy] },
  },
  {
    path: ROUTING_MAP.bestAnatomyApp,
    loadComponent: () =>
      import('../best-anatomy-app/best-anatomy-app.component').then(
        _ => _.BestAnatomyAppComponent
      ),
    canActivate: [pageMetaGuard],
    data: { ...routesMetadata[ROUTING_MAP.bestAnatomyApp] },
  },
  {
    path: '**',
    redirectTo: '/',
  },
];
