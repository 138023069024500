import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotFoundInterceptor } from '@app/shared/interceptors/error/not-found.interceptor';
import { ServerErrorsInterceptor } from '@app/shared/interceptors/error/server-errors.interceptor';
import { LocalizationInterceptor } from '@app/shared/interceptors/localization/localization.interceptor';
import { PurchaseInterceptor } from '@app/shared/interceptors/purchase.interceptor';
import { TokenInterceptor } from '@app/shared/interceptors/token/token.interceptor';

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ServerErrorsInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: NotFoundInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: PurchaseInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LocalizationInterceptor,
    multi: true,
  },
];
