<app-collection-block>
  <ng-template #title>
    <ng-container i18n> 3D модели </ng-container>
  </ng-template>
  <ng-template #content>
    <div class="models-card-list">
      @for (model of modelsList(); track model.uuid) {
        <app-collection-card
          [purchased]="model.available || (isUserInWhiteList$ | async)"
          [inProgress]="model.status === 'INACTIVE'"
          [content]="model">
          <ng-template #categoryTitle>
            {{ model | customTranslate: 'categoryName' }}
          </ng-template>
          <ng-template #pathologyTitle>
            {{ model | customTranslate: 'pathologyName' }}
          </ng-template>
          <ng-template #pathologyTypeTitle>
            {{ model | customTranslate: 'pathologyTypeName' }}
          </ng-template>
          <ng-template #modelTitle>
            {{ model | customTranslate: 'modelName' }}
          </ng-template>
          <ng-template #mark>
            <app-collection-status
              markIconSrc="star-circle-selected-dark"
              unmarkIconSrc="star-circle-unselected-dark"
              [collectionItem]="{
                uuid: model.uuid,
                type: collectionType.Bundle,
              }"></app-collection-status>
          </ng-template>
        </app-collection-card>
      }
    </div>
  </ng-template>
</app-collection-block>
