<app-collection-block>
  <ng-template #title>
    <ng-container i18n> Статьи </ng-container>
  </ng-template>
  <ng-template #content>
    <div class="article-card-list">
      @for (article of articlesList(); track article.uuid) {
        <app-pathology-article
          [pathologyUuid]="article.pathologyUuid"
          [isCollection]="true">
          <ng-template #title>
            {{ article | customTranslate: 'pathologyName' }}
          </ng-template>
          <ng-template #mark>
            <app-collection-status
              markIconSrc="star-circle-selected-dark"
              unmarkIconSrc="star-circle-unselected-dark"
              [collectionItem]="{
                uuid: article.pathologyUuid,
                type: collectionType.Article,
              }"></app-collection-status>
          </ng-template>
        </app-pathology-article>
      }
    </div>
  </ng-template>
</app-collection-block>
