<app-close-button class="modal" (click)="closeModal()"></app-close-button>

<ng-container
  *ngTemplateOutlet="
    !paymentResult || cardValidationError ? card : postValidationDialog;
    context: { $implicit: pvdData }
  ">
</ng-container>

<ng-template #card>
  <h3 class="t-heading-3 t-bold t-center" i18n>Детали оплаты</h3>
  @if (cardValidationError) {
    <div class="error-alert">
      <svg-icon key="error-monetisation"></svg-icon>
      <span class="error-alert__title">
        {{ paymentFormLocalization.errors[cardValidationError]?.title }}
      </span>
      <span class="error-alert__text">
        {{ paymentFormLocalization.errors[cardValidationError]?.text }}
      </span>
    </div>
  }

  <div class="category-info">
    <h6 class="t-heading-6 t-medium">
      {{ dialogData.category | customTranslate: 'category' }}
    </h6>
    <span class="category-info__period t-medium">
      {{
        paymentFormLocalization[dialogData.product.periodUnit | lowercase]
      }}</span
    >
    <h4 class="t-heading-4 t-bold category-info__price">
      {{
        (dialogData.product?.price / 100).toFixed(2)
          | currency: dialogData.product.currencyCode
      }}
    </h4>
  </div>

  <div
    id="card-field"
    cbCardField
    currency="EUR"
    class="card-fields"
    [styles]="styles"
    [classes]="classes"
    [placeholder]="placeholder"
    (ready)="setComponent($event)">
    <div class="card-field">
      <div
        id="number-field"
        cbNumberField
        class="input"
        placeholder=""
        (change)="setValidationErrors($event)"></div>
      <label class="label t-medium" i18n> Номер карты </label>
      <i
        class="card-field__bar"
        [class.card-field__bar_error]="validationErrors?.['number']"></i>
      @if (validationErrors?.['number']) {
        <span class="card-field__error">
          {{
            paymentFormLocalization.errors[validationErrors['number'].errorCode]
              .text
          }}
        </span>
      }
    </div>

    <div class="card-field">
      <div
        id="expiry-field"
        cbExpiryField
        class="input"
        (change)="setValidationErrors($event)"></div>
      <label class="label t-medium" i18n> Срок действия </label>
      <i
        class="card-field__bar"
        [class.card-field__bar_error]="validationErrors?.['expiry']"></i>
      @if (validationErrors?.['expiry']) {
        <span class="card-field__error">
          {{
            paymentFormLocalization.errors[validationErrors['expiry'].errorCode]
              .text
          }}
        </span>
      }
    </div>

    <div class="card-field">
      <div
        id="cvv-field"
        cbCvvField
        class="input"
        (change)="setValidationErrors($event)"></div>
      <label class="label t-medium" i18n> CVV </label>
      <i
        class="card-field__bar"
        [class.card-field__bar_error]="validationErrors?.['cvv']"></i>
      @if (validationErrors?.['cvv']) {
        <span class="card-field__error">
          {{
            paymentFormLocalization.errors[validationErrors['cvv'].errorCode]
              .text
          }}
        </span>
      }

      <div class="info-icon">
        <svg-icon key="info"></svg-icon>
        <div class="info-text" i18n>
          CVV это 3/4-значный код на обратной стороне вашей карты.
        </div>
      </div>
    </div>
  </div>

  <button
    appButton
    class="submit-button"
    type="submit"
    [loading]="cardValidationInProgress || paymentInProgress"
    (click)="purchase()"
    i18n>
    Подтвердить оплату
  </button>
</ng-template>

<ng-template #postValidationDialog let-data>
  <div class="pvd">
    <img
      class="pvd__image"
      [src]="'assets/img/monetisation/payment-' + paymentResult + '.png'"
      alt="" />
    <span class="pvd__title">{{ paymentResultMessage.title }}</span>
    <span class="pvd__text">{{ paymentResultMessage.message }}</span>
  </div>
</ng-template>
