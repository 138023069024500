<div class="container category-header">
  <div class="category-info">
    <h1
      class="t-heading-2 t-bold category-name"
      [class.category-name_bought]="!!subscription"
      i18n>
      PRO Подписка
    </h1>
    @if (!subscription) {
      <span class="category-sub-text" i18n>
        Получите полный доступ к каталогу
      </span>
      @if (subscriptionPlans?.product) {
        <div class="category-get">
          <div class="category-sub-plans">
            @for (plan of subscriptionPlans.product; let i = $index; track i) {
              <div
                class="category-sub-plan"
                [class.category-sub-plan_active]="activePlan === i"
                (click)="activePlan = i">
                <span class="category-price">{{
                  (plan.price / 100).toFixed(2) | currency: plan.currencyCode
                }}</span>
                <span class="category-period">
                  {{ periodLocalization[plan.periodUnit | lowercase] }}
                </span>
              </div>
            }
          </div>
          <button
            appButton
            class="full-width dialog-button"
            (click)="openPaymentDialog()"
            i18n>
            Оформить подписку
          </button>
          <span class="decline" i18n> Отмена в любое время </span>
        </div>
      }
    } @else {
      <div class="bought-wrapper">
        <div class="category-current-plan">
          <span class="category-current-plan__fee">
            {{
              (subscription.price / 100).toFixed(2)
                | currency: subscription.currencyCode
            }}
          </span>
          <span class="t-body t-medium">
            /
            {{ periodLocalization[subscription.periodUnit | lowercase] }}
          </span>
        </div>
        <span class="category-current-plan__end-date t-body medium">
          @if (subscription.purchasedInfo.renew) {
            <ng-container i18n>Спишется</ng-container>
          } @else {
            <ng-container i18n> Действует до </ng-container>
          }
          {{ subscription.purchasedInfo.endDate | date }}
        </span>
        @if (subscription.purchasedInfo.renew) {
          <button
            appButton
            class="full-width outlined dialog-button"
            [loading]="unsubscribing"
            (click)="unsubscribe()"
            i18n>
            Отменить подписку
          </button>
        }

        <span
          class="decline"
          [class.category-current-plan__platform_renew]="
            subscription.purchasedInfo.renew
          "
          >{{
            paymentPlatformLocalization[
              subscription.purchasedInfo.paymentPlatform
            ]
          }}</span
        >
      </div>
    }
  </div>

  <img
    alt="Preview image"
    class="category-image"
    src="assets/img/monetisation/pro-sub.png" />
</div>

@for (feature of features; let i = $index; track i) {
  <div class="container features">
    <div class="feature">
      <svg-icon class="feature-icon" [key]="feature.icon"></svg-icon>

      <h3 class="t-heading-5 t-semi-bold">
        {{ feature.title }}
      </h3>

      <p class="feature-description t-subtitle">
        {{ feature.text }}
      </p>
    </div>
  </div>
}
