import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-collection-block',
  templateUrl: './collection-block.component.html',
  styleUrls: ['./collection-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet],
})
export class CollectionBlockComponent {
  @ContentChild('content') public contentRef!: TemplateRef<any>;

  @ContentChild('title') public titleRef!: TemplateRef<any>;
}
