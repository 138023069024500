import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MobileDeviceService } from '@app/shared/services/mobile-device.service';

export const desktopGuard: CanActivateFn = (_route, state) => {
  const mobileDeviceService = inject(MobileDeviceService);
  const router = inject(Router);

  const url = state.url.replace('/m', '');

  return mobileDeviceService.isMobileDevice
    ? true
    : router.createUrlTree([url]);
};
