import { Routes } from '@angular/router';

import { ROUTING_MAP } from '../../shared/models/constants/routing-map';
import { AllCollectionComponent } from './components/all-collection/all-collection.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { ModelsComponent } from './components/models/models.component';
import { OrganSystemComponent } from './components/organ-system/organ-system.component';
import { OrgansComponent } from './components/organs/organs.component';
import { PathologyComponent } from './components/pathology/pathology.component';
import { TypesComponent } from './components/types/types.component';

export const collectionRoutes: Routes = [
  {
    path: '',
    component: AllCollectionComponent,
  },
  {
    path: ROUTING_MAP.collection.models,
    component: ModelsComponent,
  },
  {
    path: ROUTING_MAP.collection.pathologies,
    component: PathologyComponent,
  },
  {
    path: ROUTING_MAP.collection.articles,
    component: ArticlesComponent,
  },
  {
    path: ROUTING_MAP.collection.types,
    component: TypesComponent,
  },
  {
    path: ROUTING_MAP.collection.organs,
    component: OrgansComponent,
  },
  {
    path: ROUTING_MAP.collection.organSystem,
    component: OrganSystemComponent,
  },
];
