import { Routes } from '@angular/router';
import { ArticleComponent } from '@app/pages/articles/components//article/article.component';
import { ArticlesListComponent } from '@app/pages/articles/components/articles-list/articles-list.component';
import { pageMetaGuard } from '@app/shared/guards/page-meta.guard';
import { routesMetadata } from '@app/shared/models/constants/routes-metadata';
import { ROUTING_MAP } from '@app/shared/models/constants/routing-map';
import {
  ARTICLE_UUID_ROUTE_PARAM,
  CATEGORY_UUID_ROUTE_PARAM,
} from '@app/shared/models/constants/routing-params-keys';

export const articlesRoutes: Routes = [
  {
    path: `:${CATEGORY_UUID_ROUTE_PARAM}`,
    component: ArticlesListComponent,
    canActivate: [pageMetaGuard],
    data: { ...routesMetadata[ROUTING_MAP.articles] },
  },
  {
    path: `:${CATEGORY_UUID_ROUTE_PARAM}/:${ARTICLE_UUID_ROUTE_PARAM}`,
    component: ArticleComponent,
  },
];
