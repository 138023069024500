import { isPlatformBrowser } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';

import { ROUTING_MAP } from '../models/constants/routing-map';
import { AlertStatus } from '../models/enums/alert-status.enum';
import { AlertService } from '../services/alert.service';

@Injectable()
export class PurchaseInterceptor implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private alertService: AlertService,
    private router: Router
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (isPlatformBrowser(this.platformId) && error.status === 402) {
          this.alertService.show({
            message: error.error.message,
            title: error.error.title,
            status: AlertStatus.Error,
          });
          return this.navigateToCategoryPage();
        }

        return throwError(() => error);
      })
    );
  }

  private navigateToCategoryPage(): Observable<never> {
    this.router.navigate([ROUTING_MAP.index]);

    return EMPTY;
  }
}
