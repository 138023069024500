import { Dialog } from '@angular/cdk/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { EMPTY, catchError, finalize, take } from 'rxjs';
import { UserApiService } from 'src/app/shared/api/user/user-api.service';
import { BtnDirective } from 'src/app/shared/components/btn/btn.directive';
import { InputComponent } from 'src/app/shared/controls/input/input.component';
import {
  FormValidators,
  addFormValidators,
  removeFormValidators,
} from 'src/app/shared/utility/add-form-validators';
import { lengthValidator } from 'src/app/shared/validators/length.validator';
import { AlertStatus } from '../../../shared/models/enums/alert-status.enum';
import { AlertService } from '../../../shared/services/alert.service';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [BtnDirective, InputComponent, ReactiveFormsModule],
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent implements OnInit {
  public changePasswordForm: UntypedFormGroup;

  public isPasswordChanging = false;

  private formValidators: FormValidators = {
    oldPass: [Validators.required, lengthValidator(6, 30)],
    newPass: [Validators.required, lengthValidator(6, 30)],
    confirmNewPass: [Validators.required, lengthValidator(6, 30)],
  };

  constructor(
    private alertService: AlertService,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    private fb: UntypedFormBuilder,
    private dialog: Dialog,
    private userApiService: UserApiService
  ) {}

  public ngOnInit(): void {
    this.formInit();
  }

  public save(): void {
    this.changePasswordForm.enable();
    this.changePasswordForm.markAllAsTouched();
    addFormValidators(this.changePasswordForm, this.formValidators);
    this.matchPassword();
    const formValue = this.changePasswordForm.getRawValue();
    if (this.changePasswordForm.valid) {
      this.isPasswordChanging = true;

      this.userApiService
        .changePassword$({
          password: formValue.newPass,
          oldPassword: formValue.oldPass,
          confirmedPassword: formValue.confirmNewPass,
        })
        .pipe(
          take(1),
          catchError((error: HttpErrorResponse) => {
            this.callAlert(
              error.error.title,
              error.error.message,
              AlertStatus.Error
            );
            return EMPTY;
          }),
          finalize(() => {
            this.isPasswordChanging = false;
            this.cdr.markForCheck();
          })
        )
        .subscribe(() => {
          this.callAlert(
            $localize`Успешно`,
            $localize`Ваш пароль был успешно изменен`
          );
          removeFormValidators(this.changePasswordForm);
          this.changePasswordForm.reset();
          this.cdr.markForCheck();
        });
    }
  }

  private formInit(): void {
    this.changePasswordForm = this.fb.group({
      oldPass: [''],
      newPass: [''],
      confirmNewPass: [''],
    });

    this.changePasswordForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        removeFormValidators(this.changePasswordForm);
      });
  }

  private callAlert(
    title: string,
    message: string,
    status = AlertStatus.Success
  ): void {
    this.dialog.closeAll();

    this.alertService.show({
      title,
      message,
      status,
    });
  }

  private matchPassword(): void {
    if (
      this.changePasswordForm.get('oldPass')?.value &&
      this.changePasswordForm.get('newPass')?.value !==
        this.changePasswordForm.get('confirmNewPass')?.value
    ) {
      this.changePasswordForm.get('confirmNewPass')?.setErrors({ match: true });
      this.cdr.markForCheck();
    }
  }
}
