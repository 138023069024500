import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ROUTING_MAP } from '@app/shared/models/constants/routing-map';
import { MobileDeviceService } from '@app/shared/services/mobile-device.service';

export const mobileGuard: CanActivateFn = (_route, state) => {
  const mobileDeviceService = inject(MobileDeviceService);
  const router = inject(Router);

  const allowedMobileRoutes = [
    ROUTING_MAP.termsOfUse,
    ROUTING_MAP.privacy,
    ROUTING_MAP.bestAnatomyApp,
  ];

  const url = state.url.replace('/', '');

  const urlTree = ['m'];

  if (allowedMobileRoutes.includes(url)) {
    urlTree.push(url);
  }

  return mobileDeviceService.isMobileDevice
    ? router.createUrlTree(urlTree)
    : true;
};
