import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';

import { CollectionStore } from '@app/signal-store/collection/collection.store';
import { PathologyCardListComponent } from '../../../../shared/components/pathology-card-list/pathology-card-list.component';
import { ROUTING_MAP } from '../../../../shared/models/constants/routing-map';
import { CollectionType } from '../../../../shared/models/enums/collection-type.enum';
import { CustomTranslatePipe } from '../../../../shared/pipes/custom-translate/custom-translate.pipe';
import { CollectionBlockComponent } from '../collection-block/collection-block.component';

@Component({
  selector: 'app-pathology',
  templateUrl: './pathology.component.html',
  styleUrls: ['./pathology.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CollectionBlockComponent,
    PathologyCardListComponent,
    RouterLink,
    CustomTranslatePipe,
  ],
})
export class PathologyComponent {
  public readonly collectionType = CollectionType;

  public readonly routingMap = ROUTING_MAP;

  public readonly pathologiesList = this.collectionStore.collection.pathologies;

  constructor(private readonly collectionStore: CollectionStore) {}
}
