<app-collection-block>
  <ng-template #title>
    <span class="title" i18n> Системы органов </span>
  </ng-template>
  <ng-template #content>
    @for (organSystem of organSystemsList(); track organSystem.uuid) {
      <div class="organ">
        <span class="category-title">
          {{ organSystem | customTranslate: 'categoryName' }}
        </span>
        <app-pathology-card-list
          [categoryUuid]="organSystem.categoryUuid"
          [countOfItems]="organSystem.bundlesCountInPathology"
          [collectionItem]="{
            uuid: organSystem.uuid,
            type: collectionType.Pathology,
          }"
          [isPathology]="organSystem.parentIsPathology">
          <span
            title
            [routerLink]="
              '/' +
              (organSystem['pathology']
                ? routingMap.pathology
                : routingMap.anatomy) +
              '/' +
              organSystem.categoryUuid +
              '/' +
              organSystem.uuid
            ">
            {{ organSystem | customTranslate: 'pathology' }}
          </span>
        </app-pathology-card-list>
      </div>
    }
  </ng-template>
</app-collection-block>
