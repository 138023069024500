type Message = {
  title?: string;
  text?: string;
};

interface PaymentFormLocalization {
  [key: string]: string | { [key: string]: Message } | Message;
  errors: {
    [key: string]: Message;
  };
  success: Message;
}

export const paymentFormLocalization: PaymentFormLocalization = {
  year: $localize`Оплата в год`,
  month: $localize`Оплата в месяц`,
  errors: {
    invalid_card: {
      text: $localize`Недопустимый номер`,
    },
    card_number_incomplete: { text: $localize`Введите номер` },
    card_number_invalid: { text: $localize`Недопустимый номер` },
    card_expiry_invalid: { text: $localize`Недопустимый срок` },
    card_expiry_past: { text: $localize`Недопустимый срок` },
    card_cvv_incomplete: { text: $localize`Введите CVV` },
    card_cvv_invalid: { text: $localize`Недопустимый CVV` },
    ChargebeeClientError: {
      title: $localize`Произошла ошибка при проверке типа карты`,
      text: $localize`Пожалуйста, проверьте правильность введенных данных карты`,
    },
    common: {
      title: $localize`Ошибка при обработке платежа`,
      text: $localize`Транзакция не может быть выполнена. Попробуйте еще раз позже`,
    },
    '3001': {
      title: $localize`Недостаточно средств для оплаты транзакции`,
    },
  },
  success: {
    title: $localize`Операция выполнена успешно`,
    text: $localize`Теперь вам доступен весь каталог без ограничений`,
  },
};
