import { Routes } from '@angular/router';
import { mainRoutes } from '@app/pages/main/main.routes';
import { mobileRoutes } from '@app/pages/mobile-device-stub/mobile.routes';
import { desktopGuard } from '@app/shared/guards/desktop/desktop.guard';
import { mobileGuard } from '@app/shared/guards/mobile/mobile.guard';

export const routes: Routes = [
  {
    path: 'm',
    loadComponent: () =>
      import('./pages/mobile-device-stub/mobile-root.component').then(
        _ => _.MobileRootComponent
      ),
    children: mobileRoutes,
    canActivate: [desktopGuard],
  },
  {
    path: '',
    loadComponent: () =>
      import('./pages/main/main.component').then(_ => _.MainComponent),
    children: mainRoutes,
    canActivate: [mobileGuard],
  },
];
