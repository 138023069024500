<app-collection-block>
  <ng-template #title>
    <ng-container i18n>Типы патологий</ng-container>
  </ng-template>
  <ng-template #content>
    <div class="types-card-list">
      @for (type of typesList(); track type.uuid) {
        <app-collection-card
          [purchased]="type.available || (isUserInWhiteList$ | async)"
          [inProgress]="!type.firstBundleUuid"
          [content]="type">
          <ng-template #mark>
            <app-collection-status
              markIconSrc="star-circle-selected-dark"
              unmarkIconSrc="star-circle-unselected-dark"
              [collectionItem]="{
                uuid: type.uuid,
                type: collectionType.PathologyType,
              }"></app-collection-status>
          </ng-template>
          <ng-template #categoryTitle>
            {{ type | customTranslate: 'categoryName' }}
          </ng-template>
          <ng-template #pathologyTitle>
            {{ type | customTranslate: 'pathologyName' }}
          </ng-template>
          <ng-template #pathologyTypeTitle>
            {{ type | customTranslate: 'type' }}
          </ng-template>
        </app-collection-card>
      }
    </div>
  </ng-template>
</app-collection-block>
