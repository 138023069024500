import { ActionReducerMap } from '@ngrx/store';
import { IAppState } from '@store/app-state.interface';
import { articlesFK, articlesReducer } from '@store/articles/articles.reducer';
import {
  categoriesFK,
  categoriesReducer,
} from '@store/categories/categories.reducer';
import { sortFK, sortReducer } from '@store/sort/sort.reducer';
import { userFK, userReducer } from '@store/user/user.reducer';
import { viewerFK, viewerReducer } from '@store/viewer/viewer.reducer';

export const reducers: ActionReducerMap<IAppState> = {
  [articlesFK]: articlesReducer,
  [categoriesFK]: categoriesReducer,
  [sortFK]: sortReducer,
  [userFK]: userReducer,
  [viewerFK]: viewerReducer,
};
