@if (article) {
  <div class="article-page">
    <div class="navigation-buttons">
      <span class="back" (click)="back()">
        <svg-icon key="arrow-left"></svg-icon>
        <ng-container i18n>Назад</ng-container>
      </span>

      <button appButton class="outlined" (click)="goToPathology()" i18n>
        К патологии
      </button>
    </div>
    <div class="article-page__header">
      <h1 #articleTitle class="title">
        {{ article | customTranslate: 'title' }}
      </h1>
      <div class="buttons__right">
        <app-collection-status
          class="icon"
          [collectionItem]="{
            uuid: article.pathologyUuid,
            type: collectionType.Article,
          }"></app-collection-status>

        <app-link-share
          [linkType]="linkType.Article"
          [linkShareParams]="{
            categoryUuid: article.categoryUuid,
            pathologyUuid: article.pathologyUuid,
          }"></app-link-share>

        <button
          class="button"
          [disabled]="
            !(article | customTranslate: 'link') || downloadInProgress()
          "
          (click)="downloadArticle()">
          @if (!downloadInProgress()) {
            <svg-icon class="icon" key="download" size="lg-plus"></svg-icon>
          } @else {
            <div class="loading-spinner article-loading-spinner"></div>
          }
        </button>

        <button class="button" (click)="print()">
          <svg-icon class="icon" key="print" size="lg-plus"></svg-icon>
        </button>
      </div>
    </div>
    <div #articleContent>
      <app-article-viewer [article]="article"></app-article-viewer>
    </div>

    <div class="button__bottom">
      <button appButton class="outlined" (click)="goToPathology()" i18n>
        К патологии
      </button>
    </div>
  </div>
}
