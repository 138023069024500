import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { MetaService } from '@app/shared/services/meta/meta.service';

export const pageMetaGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const meta = inject(MetaService);

  const title = route.data['title'];

  meta.setMetadata({
    meta: route.data['meta'],
    title,
  });

  return true;
};
