import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '@app/shared/services/token/token.service';
import { environment } from '@env/environment';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private accessTokenRefreshing = false;

  private accessTokenSubject = new BehaviorSubject<string>(null);

  constructor(
    private router: Router,
    private tokenService: TokenService
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.url.includes(environment.apiUrl)) {
      return next.handle(request);
    }

    const accessToken = this.tokenService.getAccessToken();

    return next.handle(this.addAuthorizationHeader(request, accessToken)).pipe(
      catchError(error => {
        if (
          error instanceof HttpErrorResponse &&
          (error.status === 401 || error.status === 403)
        ) {
          return this.handleUnauthorizedError(request, next);
        }

        return throwError(error);
      })
    );
  }

  private handleUnauthorizedError(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.accessTokenRefreshing) {
      this.accessTokenRefreshing = true;
      this.accessTokenSubject.next(null);

      const refreshToken = this.tokenService.getRefreshToken();

      return this.tokenService.refreshToken$(refreshToken).pipe(
        catchError(err => {
          this.tokenService.deleteTokens();
          return throwError(() => err);
        }),
        tap(user => {
          this.tokenService.setToken(user.userTokenDTO);
        }),
        switchMap(({ userTokenDTO }) => {
          this.accessTokenRefreshing = false;
          this.accessTokenSubject.next(userTokenDTO.accessToken);

          return next.handle(
            this.addAuthorizationHeader(request, userTokenDTO.accessToken)
          );
        })
      );
    }

    return this.accessTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(token =>
        next.handle(this.addAuthorizationHeader(request, token))
      )
    );
  }

  private addAuthorizationHeader(
    request: HttpRequest<any>,
    accessToken: string
  ) {
    const headers = accessToken
      ? request.headers.set('Authorization', `Bearer ${accessToken}`)
      : request.headers;

    return request.clone({
      headers,
    });
  }
}
