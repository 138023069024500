import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IRole } from '@src/app/shared/models/interfaces/auth/role.interface';
import { ILoginUser } from '@src/app/shared/models/interfaces/login-user.interface';
import { editUser, editUserSuccess } from '@store/user/user.actions';
import {
  selectIsUserEditing,
  selectUserInfo,
} from '@store/user/user.selectors';
import { distinctUntilChanged, filter } from 'rxjs';
import { BtnDirective } from 'src/app/shared/components/btn/btn.directive';
import { AutocompleteComponent } from 'src/app/shared/controls/autocomplete/autocomplete.component';
import { DropdownComponent } from 'src/app/shared/controls/dropdown/dropdown.component';
import { InputComponent } from 'src/app/shared/controls/input/input.component';
import { CountriesService } from 'src/app/shared/services/countries/countries.service';
import { countryValidator } from 'src/app/shared/validators/country.validator';

import { PHONE_NUMBER_REGEXP } from '../../../shared/models/constants/regexp';
import { userRoles } from '../../../shared/models/constants/user-roles';

@Component({
  selector: 'app-personal-information',
  standalone: true,
  imports: [
    AsyncPipe,
    AutocompleteComponent,
    BtnDirective,
    DropdownComponent,
    InputComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalInformationComponent implements OnInit {
  public maxLength = {
    small: 50,
    medium: 100,
    large: 1000,
    veryLarge: 3000,
  };

  public personalInformationForm!: UntypedFormGroup;

  public roles: IRole[] = userRoles;

  public defaultFormValue: any;

  public userId!: string;

  public disabledButton: boolean = true;

  public isLastSelect = false;

  public isSaving = this.store.select(selectIsUserEditing);

  constructor(
    public countriesService: CountriesService,
    private actions: Actions,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    private fb: UntypedFormBuilder,
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.formInit();
    this.loadUser();
    this.countriesService.setupLocalizedCountries();
    this.personalInformationForm.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.disabledButton = false;
        this.cdr.markForCheck();
      });

    this.actions
      .pipe(ofType(editUserSuccess), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.disabledButton = true;
        this.cdr.markForCheck();
      });
  }

  public save(): void {
    if (this.personalInformationForm.valid) {
      let formData = this.personalInformationForm.getRawValue();
      const countryCode = this.countriesService.getCountryCodeByCountryName(
        formData.country
      );

      formData = {
        ...formData,
        country: countryCode,
      };

      this.store.dispatch(
        editUser({ editedInfo: formData, userId: this.userId })
      );
    }
  }

  public phoneMask(event: KeyboardEvent) {
    if (!PHONE_NUMBER_REGEXP.test(event.key)) {
      event.preventDefault();
    }
  }

  public reset(): void {
    this.personalInformationForm.setValue(this.defaultFormValue);
    this.disabledButton = true;
    if (this.defaultFormValue.participateAs === 'OTHER') {
      this.isLastSelect = true;
      return;
    }
    this.isLastSelect = false;
  }

  public optionSelect(isLast: boolean): void {
    const field = this.personalInformationForm.get('participateAsDescription');
    this.isLastSelect = isLast;
    field?.setValue(null);
    field?.clearValidators();
  }

  private formInit(): void {
    this.personalInformationForm = this.fb.group({
      city: [''],
      country: [
        '',
        [countryValidator(this.countriesService.fulfilledCountriesList)],
      ],
      experience: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phoneNumber: [''],
      position: [''],
      socialNetworkLink: [''],
      specialty: [''],
      participateAs: ['', [Validators.required]],
      participateAsDescription: [''],
    });
  }

  private loadUser(): void {
    this.store
      .select(selectUserInfo)
      .pipe(
        filter(userInfo => Boolean(userInfo)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(userInfo => {
        this.userId = userInfo.id;
        this.defaultFormValue = this.userFormMapper(userInfo);
        this.reset();
      });
  }

  private userFormMapper(user: ILoginUser): any {
    return {
      city: user.city,
      country:
        this.countriesService.getCountryNameByCountryCode(user.country) ||
        user.country ||
        $localize`Не выбрано`,
      experience: user.biography,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      position: user.jobTitle,
      socialNetworkLink: user.socialNetworkLink,
      specialty: user.specialty,
      participateAs: user.participateAs,
      participateAsDescription: user.participateAsDescription,
    };
  }
}
