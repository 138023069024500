import { Injectable } from '@angular/core';
import { RedirectService } from '@app/shared/services/redirect.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AlertStatus } from '@src/app/shared/models/enums/alert-status.enum';
import { ILoginUser } from '@src/app/shared/models/interfaces/login-user.interface';
import { catchError, exhaustMap, map, mergeMap, of, tap } from 'rxjs';
import { AuthApiService } from 'src/app/shared/api/auth/auth-api.service';
import { SubscriptionsApiService } from 'src/app/shared/api/subscriptions-api.service';
import { UserApiService } from 'src/app/shared/api/user/user-api.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { TokenService } from 'src/app/shared/services/token/token.service';
import * as UserActions from './user.actions';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private authApiService: AuthApiService,
    private redirectService: RedirectService,
    private subscriptionsApiService: SubscriptionsApiService,
    private tokenService: TokenService,
    private userApiService: UserApiService
  ) {}

  public getUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.getUser),
      mergeMap(() => {
        return this.userApiService.getUser$().pipe(
          map(userInfo =>
            UserActions.getUserSuccess({
              userInfo,
            })
          ),
          catchError(error =>
            of(
              UserActions.getUserFailure({
                error: error?.message,
              })
            )
          )
        );
      })
    );
  });

  public logoutUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.logoutUser),
      mergeMap(() => {
        return this.authApiService.logout$().pipe(
          map(() => {
            return UserActions.logoutUserSuccess();
          }),
          catchError(error =>
            of(
              UserActions.logoutUserFailure({
                error: error?.message,
              })
            )
          )
        );
      })
    );
  });

  public loginUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loginUser),
      exhaustMap(action => {
        return this.authApiService.login$(action.loginData).pipe(
          tap(userInfo => {
            this.tokenService.setToken(userInfo.userTokenDTO);
            this.redirectService.redirectToMemorizedUrl();
          }),
          map(userInfo =>
            UserActions.loginUserSuccess({
              userLogin: userInfo.userDTO,
            })
          ),
          catchError(error => {
            this.alertService.show({
              title: error.error?.title || $localize`Что-то пошло не так`,
              message:
                error.error?.message ||
                $localize`Мы работаем над решением проблемы и благодарим вас за терпение`,
              status: AlertStatus.Error,
            });

            return of(UserActions.loginUserFailure());
          })
        );
      })
    );
  });

  public editUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.editUser),
      exhaustMap(action => {
        return this.userApiService
          .editUser$(action.editedInfo, action.userId)
          .pipe(
            map(userInfo => {
              const {
                experience: biography,
                position: jobTitle,
                ...editUser
              } = userInfo;

              const loggedUserInfo: ILoginUser = {
                ...editUser,
                biography,
                jobTitle,
                medicalField: '',
              };

              return UserActions.editUserSuccess({
                userInfo: loggedUserInfo,
              });
            }),
            catchError(error =>
              of(UserActions.editUserFailure({ error: error?.message }))
            )
          );
      })
    );
  });

  public getUserSubscriptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.getUserSubscriptions),
      mergeMap(() => {
        return this.subscriptionsApiService.getSubscriptions$().pipe(
          map(userSubs => {
            userSubs.forEach(sub =>
              sub.product.sort((a, b) => a.sequence - b.sequence)
            );
            return UserActions.getUserSubscriptionsSuccess({
              userSubs,
            });
          }),
          catchError(error =>
            of(
              UserActions.getUserSubscriptionsFailure({
                error: error?.message,
              })
            )
          )
        );
      })
    );
  });

  public checkEmailExistsOnAuth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.checkUserEmailOnAuth),
      mergeMap(action => {
        return this.userApiService.checkEmail$(action.email).pipe(
          map(emailCheck => {
            return UserActions.checkUserEmailOnAuthSuccess({ emailCheck });
          }),
          catchError(error =>
            of(
              UserActions.checkUserEmailOnAuthFailure({
                error: error?.message,
              })
            )
          )
        );
      })
    );
  });
}
