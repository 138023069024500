import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError, of, take } from 'rxjs';

import { CategoryAPIService } from '../api/category/category-api.service';

export const getPathologyByCategoryUuidResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const categoryService = inject(CategoryAPIService);

  return categoryService.getCategoryData$(route.params['uuid']).pipe(
    take(1),
    catchError(err => {
      console.error(err);
      return of(null);
    })
  );
};
