import { ChangeDetectionStrategy, Component } from '@angular/core';

import { CollectionStore } from '@app/signal-store/collection/collection.store';
import { CollectionStatusComponent } from '../../../../shared/components/collection-status/collection-status.component';
import { PathologyArticleComponent } from '../../../../shared/components/pathology-article/pathology-article.component';
import { CollectionType } from '../../../../shared/models/enums/collection-type.enum';
import { CustomTranslatePipe } from '../../../../shared/pipes/custom-translate/custom-translate.pipe';
import { CollectionBlockComponent } from '../collection-block/collection-block.component';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CollectionBlockComponent,
    PathologyArticleComponent,
    CollectionStatusComponent,
    CustomTranslatePipe,
  ],
})
export class ArticlesComponent {
  public collectionType = CollectionType;

  public readonly articlesList = this.collectionStore.collection.articles;

  constructor(private readonly collectionStore: CollectionStore) {}
}
