import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CollectionBlockComponent } from '@app/pages/collection/components/collection-block/collection-block.component';
import { CollectionCardComponent } from '@app/shared/components/collection-card/collection-card.component';
import { CollectionStatusComponent } from '@app/shared/components/collection-status/collection-status.component';
import { CollectionType } from '@app/shared/models/enums/collection-type.enum';
import { CustomTranslatePipe } from '@app/shared/pipes/custom-translate/custom-translate.pipe';
import { CollectionStore } from '@app/signal-store/collection/collection.store';
import { Store } from '@ngrx/store';
import { selectIsUserInWhiteList } from '@store/user/user.selectors';

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    CollectionBlockComponent,
    CollectionCardComponent,
    CollectionStatusComponent,
    CustomTranslatePipe,
  ],
})
export class ModelsComponent {
  public readonly collectionType = CollectionType;

  public readonly modelsList = this.collectionStore.collection.bundles;

  public isUserInWhiteList$ = this.store.select(selectIsUserInWhiteList);

  constructor(
    private readonly collectionStore: CollectionStore,
    private store: Store
  ) {}
}
