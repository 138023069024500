import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnInit,
  effect,
} from '@angular/core';

import { Collection } from '@app/signal-store/collection/collection-state.interface';
import { CollectionStore } from '@app/signal-store/collection/collection.store';
import { ArticlesComponent } from '../articles/articles.component';
import { ModelsComponent } from '../models/models.component';
import { OrganSystemComponent } from '../organ-system/organ-system.component';
import { OrgansComponent } from '../organs/organs.component';
import { PathologyComponent } from '../pathology/pathology.component';
import { TypesComponent } from '../types/types.component';

@Component({
  selector: 'app-all-collection',
  templateUrl: './all-collection.component.html',
  styleUrls: ['./all-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModelsComponent,
    TypesComponent,
    PathologyComponent,
    OrgansComponent,
    OrganSystemComponent,
    ArticlesComponent,
  ],
})
export class AllCollectionComponent implements OnInit {
  public blocksVisibility: Record<string, boolean> = {
    articles: true,
    types: true,
    bundles: true,
    pathologies: true,
    organSystems: true,
    organs: true,
  };

  constructor(
    private readonly collectionStore: CollectionStore,
    private cdr: ChangeDetectorRef,
    private injector: Injector
  ) {}

  public ngOnInit(): void {
    this.getCollectionState();
  }

  private getCollectionState(): void {
    effect(
      () => {
        const collection = this.collectionStore.collection();

        for (let key in this.blocksVisibility) {
          this.blocksVisibility[key] = Boolean(
            collection[key as keyof Collection]?.length
          );
        }

        this.cdr.markForCheck();
      },
      { injector: this.injector }
    );
  }
}
