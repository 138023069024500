import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Subscription } from '../models/interfaces/subscription.interface';

@Injectable({ providedIn: 'root' })
export class SubscriptionsApiService {
  constructor(private http: HttpClient) {}

  public getSubscriptions$(): Observable<Subscription[]> {
    return this.http.get<Subscription[]>(
      `${environment.apiUrl}/web/subscriptions`
    );
  }
}
