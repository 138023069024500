import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { PathologyTypeApiService } from '@app/shared/api/pathology-type/pathology-type-api.service';
import { MetaService } from '@app/shared/services/meta/meta.service';
import { map, of, switchMap, tap } from 'rxjs';
import { CategoryAPIService } from '../api/category/category-api.service';
import { ROUTING_MAP } from '../models/constants/routing-map';

export const bundlePageMetaGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const categoryService = inject(CategoryAPIService);
  const meta = inject(MetaService);
  const pathologyTypeApiService = inject(PathologyTypeApiService);
  const router = inject(Router);

  const uuids: string[] = state.url.match(
    /([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/gm
  );

  switch (uuids?.length) {
    case 3:
      return categoryService.getCategoryData$(uuids[0]).pipe(
        tap(category => {
          const categoryName = category.categoryEn;
          const pathologyName = category.pathologies.find(
            pathology => pathology.uuid === uuids[1]
          ).pathologyEn;
          const pathologyType = category.pathologies
            .find(pathology => pathology.uuid === uuids[1])
            .pathologyTypes.find(type => type.uuid === uuids[2]);

          const pathologyTypeName = pathologyType.typeEn;

          const title = `${pathologyTypeName} ${$localize`в 3D - VOKA Anatomy Pro`}`;

          meta.setMetadata({
            meta: [
              {
                property: 'og:title',
                content: title,
              },
              {
                property: 'og:description',
                content: `${pathologyTypeName} - ${pathologyName} - ${categoryName}`,
              },
            ],
            title,
          });
        }),
        switchMap(() =>
          pathologyTypeApiService.getPathologyType$(uuids[2]).pipe(
            map(pathologyType => {
              const bundleUuid = pathologyType.bundles.find(
                bundle => bundle.status === 'ACTIVE'
              ).uuid;

              return router.createUrlTree([
                ROUTING_MAP.models,
                ...uuids,
                bundleUuid,
              ]);
            })
          )
        )
      );

    case 4:
      return pathologyTypeApiService.getPathologyType$(uuids[2]).pipe(
        map(type => {
          const title = `${type.typeEn} ${$localize`в 3D - VOKA Anatomy Pro`}`;

          meta.setMetadata({
            meta: [
              {
                property: 'og:title',
                content: title,
              },
              {
                property: 'og:description',
                content: `${type.typeEn} ${$localize`в 3D`} - ${
                  type.bundles.find(bundle => bundle.uuid === uuids[3])
                    .modelNameEn
                }`,
              },
            ],
            title,
          });

          return true;
        })
      );

    default:
      return of(true);
  }
};
