import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IArticleItem } from '@app/shared/models/interfaces/article/article-item.interface';
import { IArticleListItem } from '@app/shared/models/interfaces/article/article-list-item.interface';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ArticleApiService {
  constructor(private http: HttpClient) {}

  public getArticle$(pathologyUuid: string): Observable<Blob> {
    return this.http.get(
      `${environment.apiUrl}/v4/articles/pathology/${pathologyUuid}/download`,
      { responseType: 'blob' }
    );
  }

  public getArticleHtmlByPathologyUuid$(
    pathologyUuid: string
  ): Observable<IArticleItem> {
    return this.http.get<IArticleItem>(
      `${environment.apiUrl}/v1/pathologies/${pathologyUuid}/articles`
    );
  }

  public getArticleList$(categoryUuid: string): Observable<IArticleListItem[]> {
    return this.http.get<IArticleListItem[]>(
      `${environment.apiUrl}/v1/categories/${categoryUuid}/articles`
    );
  }
}
