import { addIcon } from '@app/svg/add';
import { minusIcon } from '@app/svg/minus';
import { arrowIcon } from '@src/app/svg/arrow';
import { arrowBackIcon } from '@src/app/svg/arrow-back';
import { arrowLeftIcon } from '@src/app/svg/arrow-left';
import { arrowRightIcon } from '@src/app/svg/arrow-right';
import { articleIcon } from '@src/app/svg/article';
import { articleShareIcon } from '@src/app/svg/article-share';
import { bulbIcon } from '@src/app/svg/bulb';
import { burgerIcon } from '@src/app/svg/burger';
import { burgerHalfIcon } from '@src/app/svg/burger-half';
import { checkMarkIcon } from '@src/app/svg/check-mark';
import { copyIcon } from '@src/app/svg/copy';
import { crossIcon } from '@src/app/svg/cross';
import { devicesIcon } from '@src/app/svg/devices';
import { doubleArrowLeftIcon } from '@src/app/svg/double-arrow-left';
import { downArrowIcon } from '@src/app/svg/down-arrow';
import { downloadIcon } from '@src/app/svg/download';
import { dragIcon } from '@src/app/svg/drag';
import { dropdownArrowIcon } from '@src/app/svg/dropdown-arrow';
import { errorIcon } from '@src/app/svg/error';
import { errorMonetisationIcon } from '@src/app/svg/error-monetisation';
import { eyeOffIcon } from '@src/app/svg/eye-off';
import { eyeOnIcon } from '@src/app/svg/eye-on';
import { facebookIcon } from '@src/app/svg/facebook';
import { fullscreenEnterIcon } from '@src/app/svg/fullscreen-enter';
import { fullscreenLeaveIcon } from '@src/app/svg/fullscreen-leave';
import { hemodynamicsIcon } from '@src/app/svg/hemodynamics';
import { hideIcon } from '@src/app/svg/hide';
import { imageIcon } from '@src/app/svg/image';
import { infoIcon } from '@src/app/svg/info';
import { instagramIcon } from '@src/app/svg/instagram';
import { isolateIcon } from '@src/app/svg/isolate';
import { landingAppStoreIcon } from '@src/app/svg/landing-app-store';
import { landingGooglePlayIcon } from '@src/app/svg/landing-google-play';
import { linkShareIcon } from '@src/app/svg/link-share';
import { linkedinIcon } from '@src/app/svg/linkedin';
import { lockIcon } from '@src/app/svg/lock';
import { menuIcon } from '@src/app/svg/menu';
import { modelsIcon } from '@src/app/svg/models';
import { modelsArticleIcon } from '@src/app/svg/models-article';
import { modelsModelsIcon } from '@src/app/svg/models-models';
import { modelsPathologiesIcon } from '@src/app/svg/models-pathologies';
import { modelsScreenshotIcon } from '@src/app/svg/models-screenshot';
import { noPurchaseLockIcon } from '@src/app/svg/no-purchase-lock';
import { openArticleIcon } from '@src/app/svg/open-article';
import { pathologyIcon } from '@src/app/svg/pathology';
import { pen2DIcon } from '@src/app/svg/pen-2d';
import { pen3DIcon } from '@src/app/svg/pen-3d';
import { pointerIcon } from '@src/app/svg/pointer';
import { primogemIcon } from '@src/app/svg/primogem';
import { printIcon } from '@src/app/svg/print';
import { ratingStarIcon } from '@src/app/svg/rating-star';
import { redoIcon } from '@src/app/svg/redo';
import { refsMenuIcon } from '@src/app/svg/refs-menu';
import { resetIcon } from '@src/app/svg/reset';
import { reviewIcon } from '@src/app/svg/review';
import { rightArrowIcon } from '@src/app/svg/right-arrow';
import { searchIcon } from '@src/app/svg/search';
import { settingsIcon } from '@src/app/svg/settings';
import { shareIcon } from '@src/app/svg/share';
import { showIcon } from '@src/app/svg/show';
import { showAllIcon } from '@src/app/svg/show-all';
import { sizeMinusIcon } from '@src/app/svg/size-minus';
import { sizePlusIcon } from '@src/app/svg/size-plus';
import { starCircleSelectedIcon } from '@src/app/svg/star-circle-selected';
import { starCircleSelectedDarkIcon } from '@src/app/svg/star-circle-selected-dark';
import { starCircleUnselectedIcon } from '@src/app/svg/star-circle-unselected';
import { starCircleUnselectedDarkIcon } from '@src/app/svg/star-circle-unselected-dark';
import { starSelectedIcon } from '@src/app/svg/star-selected';
import { starSelectedDarkIcon } from '@src/app/svg/star-selected-dark';
import { starUnselectedIcon } from '@src/app/svg/star-unselected';
import { starUnselectedDarkIcon } from '@src/app/svg/star-unselected-dark';
import { successIcon } from '@src/app/svg/success';
import { systemsBoneJointsIcon } from '@src/app/svg/systems-bone-joints';
import { systemsBonesIcon } from '@src/app/svg/systems-bones';
import { systemsMusclesIcon } from '@src/app/svg/systems-muscles';
import { systemsNervesIcon } from '@src/app/svg/systems-nerves';
import { systemsOrgansIcon } from '@src/app/svg/systems-organs';
import { systemsPartsIcon } from '@src/app/svg/systems-parts';
import { systemsSkinIcon } from '@src/app/svg/systems-skin';
import { systemsVesselsIcon } from '@src/app/svg/systems-vessels';
import { textIcon } from '@src/app/svg/text';
import { thanksAppIcon } from '@src/app/svg/thanks-app';
import { thanksEmailIcon } from '@src/app/svg/thanks-email';
import { thanksWebIcon } from '@src/app/svg/thanks-web';
import { toolsIcon } from '@src/app/svg/tools';
import { trashIcon } from '@src/app/svg/trash';
import { treeArrowIcon } from '@src/app/svg/tree-arrow';
import { undoIcon } from '@src/app/svg/undo';
import { userStudentIcon } from '@src/app/svg/user-student';
import { viewerToolColorBlackIcon } from '@src/app/svg/viewer-tool-color-black';
import { viewerToolColorBlueIcon } from '@src/app/svg/viewer-tool-color-blue';
import { viewerToolColorGreenIcon } from '@src/app/svg/viewer-tool-color-green';
import { viewerToolColorRedIcon } from '@src/app/svg/viewer-tool-color-red';
import { viewerToolColorYellowIcon } from '@src/app/svg/viewer-tool-color-yellow';
import { youtubeIcon } from '@src/app/svg/youtube';

export const SVG_REGISTRY = [
  addIcon,
  arrowBackIcon,
  arrowLeftIcon,
  arrowRightIcon,
  arrowIcon,
  articleShareIcon,
  articleIcon,
  bulbIcon,
  burgerHalfIcon,
  burgerIcon,
  checkMarkIcon,
  copyIcon,
  crossIcon,
  devicesIcon,
  doubleArrowLeftIcon,
  downArrowIcon,
  downloadIcon,
  dragIcon,
  dropdownArrowIcon,
  errorMonetisationIcon,
  errorIcon,
  eyeOffIcon,
  eyeOnIcon,
  facebookIcon,
  fullscreenEnterIcon,
  fullscreenLeaveIcon,
  hemodynamicsIcon,
  hideIcon,
  imageIcon,
  infoIcon,
  instagramIcon,
  isolateIcon,
  landingAppStoreIcon,
  landingGooglePlayIcon,
  linkShareIcon,
  linkedinIcon,
  lockIcon,
  menuIcon,
  minusIcon,
  modelsArticleIcon,
  modelsModelsIcon,
  modelsPathologiesIcon,
  modelsScreenshotIcon,
  modelsIcon,
  noPurchaseLockIcon,
  openArticleIcon,
  pathologyIcon,
  pen2DIcon,
  pen3DIcon,
  pointerIcon,
  primogemIcon,
  printIcon,
  ratingStarIcon,
  redoIcon,
  refsMenuIcon,
  resetIcon,
  reviewIcon,
  rightArrowIcon,
  searchIcon,
  settingsIcon,
  shareIcon,
  showAllIcon,
  showIcon,
  sizeMinusIcon,
  sizePlusIcon,
  starCircleSelectedDarkIcon,
  starCircleSelectedIcon,
  starCircleUnselectedDarkIcon,
  starCircleUnselectedIcon,
  starSelectedDarkIcon,
  starSelectedIcon,
  starUnselectedDarkIcon,
  starUnselectedIcon,
  successIcon,
  systemsBoneJointsIcon,
  systemsBonesIcon,
  systemsMusclesIcon,
  systemsNervesIcon,
  systemsOrgansIcon,
  systemsPartsIcon,
  systemsSkinIcon,
  systemsVesselsIcon,
  textIcon,
  thanksAppIcon,
  thanksEmailIcon,
  thanksWebIcon,
  toolsIcon,
  trashIcon,
  treeArrowIcon,
  undoIcon,
  userStudentIcon,
  viewerToolColorBlackIcon,
  viewerToolColorBlueIcon,
  viewerToolColorGreenIcon,
  viewerToolColorRedIcon,
  viewerToolColorYellowIcon,
  youtubeIcon,
];
